
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="print-dialog-view">
    <div class="header">
        <div class="header-text">{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</div>
        <div class="header-icon" (click)="close()" matTooltip="{{ 'CLOSE' | translate }}">
            <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
        </div>
    </div>
    <div class="mobile-header">
        <div class="header-icon" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
        <div class="header-text">{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</div>
    </div>
    <div *ngIf="!isMobileScreen" class="print-dialog-body">
        <div class="calendars-input-block custom-input-dropdown">
            <button [matMenuTriggerFor]="calendarMenu" class="appt-btn">
                <span class="input-label">{{ 'CALENDARS.CALENDARS' | translate }}</span>
                <span class="input-value">{{ selectedCalendarFolder}}</span>
                <span class="dropdown-trigger-Icon">
                    <ng-container [ngTemplateOutlet]="matSelectTriggerIcon"></ng-container>
                </span>
            </button>
        </div>
        <div class="date-picker-block" [ngClass]="{'range-input' : selectedRadio === 'dateRange'}">
            <div class="date-and-radio-block">
                <vnc-datepicker *ngIf="selectedRadio === 'currentDate'" [lang]="'en'" [cancelLabel]="'CANCEL' | translate" [applyLabel]="'APPLY_LBL' | translate" [singleSelected]="{startDate : todayDateFormControl?.value}"
                    placeholder="{{ 'CALENDARS.DATE_LBL' | translate }}" [singleDatePickerIcon]="true" [singleDatePicker]="true"
                    (datesUpdatedEvent)="datesUpdatedEvent($event,'today')" [format]="'DD.MM.YYYY'"
                    [showTimePicker]="false"></vnc-datepicker>
                <vnc-datepicker *ngIf="selectedRadio === 'dateRange'" [cancelLabel]="'CANCEL' | translate" [applyLabel]="'APPLY_LBL' | translate" [singleSelected]="{startDate : startDateFormControl?.value}"
                    [lang]="'en'" placeholder="{{ 'CALENDARS.DATE_LBL' | translate }}" [singleDatePickerIcon]="true" [singleDatePicker]="true"
                    (datesUpdatedEvent)="datesUpdatedEvent($event,'startDate')" [format]="'DD.MM.YYYY'"
                    [showTimePicker]="false"></vnc-datepicker>
                    <div class="day-range-radio-action">
                        <vnc-radio-button label="" [options]="[{ label: 'CALENDARS.DATE_LBL' | translate , value: 'currentDate'}]" [disabled]="false"
                            (change)="selectedRadio=$event.value" [selectedValue]="selectedRadio">
                        </vnc-radio-button>
                        <vnc-radio-button label="" [options]="[{ label: 'RANGE' | translate, value: 'dateRange'}]" [disabled]="false"
                            (change)="selectedRadio=$event.value" [selectedValue]="selectedRadio">
                        </vnc-radio-button>
                    </div>
            </div>
            <vnc-datepicker *ngIf="selectedRadio === 'dateRange'" [cancelLabel]="'CANCEL' | translate" [applyLabel]="'APPLY_LBL' | translate" [singleSelected]="{startDate : endDateFormControl?.value}"
                [lang]="'en'" placeholder="{{ 'END_DATE' | translate }}" [singleDatePickerIcon]="true" [singleDatePicker]="true"
                (datesUpdatedEvent)="datesUpdatedEvent($event,'endDate')" [format]="'DD.MM.YYYY'"
                [showTimePicker]="false"></vnc-datepicker>
        </div>
        <div class="print-view print-view-input-block custom-input-dropdown">
            <button [matMenuTriggerFor]="printTypes" class="appt-btn">
                <span class="input-label">{{ 'CALENDARS.PRINT_VIEW_LBL' | translate }}</span>
                <span class="input-value" *ngIf="printViewType === 'day'">{{ 'CALENDARS.DAY_VIEW_TITLE' | translate }}</span>
                <span class="input-value" *ngIf="printViewType === 'week'">{{ 'CALENDARS.WEEK_VIEW_TITLE' | translate }}</span>
                <span class="input-value" *ngIf="printViewType === 'month'">{{ 'CALENDARS.MONTH_VIEW_TITLE' | translate }}</span>
                <span class="input-value" *ngIf="printViewType === 'list'">{{ 'CALENDARS.LIST_VIEW_TITLE' | translate }}</span>
                <span class="dropdown-trigger-Icon">
                    <ng-container [ngTemplateOutlet]="matSelectTriggerIcon"></ng-container>
                </span>
            </button>
        </div>
        <div class="specific-hour-checkbox" *ngIf="printViewType !=='month' && printViewType !=='list'">
            <vnc-checkbox label="{{ 'SPECIFIC_HOURS' | translate }}" [checked]="IsSpecificHours" (change)="IsSpecificHours = $event"></vnc-checkbox>
        </div>
        <div class="hours-div hours-input-block custom-input-dropdown" *ngIf="printViewType !=='month' && printViewType !=='list' && IsSpecificHours">
            <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="startTimePointMenu">
                <span class="input-label">{{ 'TO' | translate }}</span>
                <span class="input-value">{{ startTimeControl | date: "hh:mm a" }}</span>
                <span class="dropdown-trigger-Icon">
                    <ng-container [ngTemplateOutlet]="matSelectTriggerIcon"></ng-container>
                </span>
            </button>
            <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="endTimePointMenu">
                <span class="input-label">{{ 'FROM' | translate }}</span>
                <span class="input-value">{{ endTimeControl | date: "hh:mm a" }}</span>
                <span class="dropdown-trigger-Icon">
                    <ng-container [ngTemplateOutlet]="matSelectTriggerIcon"></ng-container>
                </span>
            </button>
        </div>
        <div class="sub-header">{{ 'PRINT_OPTIONS' | translate }}</div>
        <div class="options print-option-checkbox-field">
            <vnc-checkbox *ngIf="printViewType === 'day'" label="{{ 'CALENDARS.PRINT_ONE_DAY_PAGE_LBL' | translate }}" [checked]="oneDayPage"></vnc-checkbox>
            <vnc-checkbox *ngIf="printViewType === 'week' || printViewType === 'month'" label="{{ 'CALENDARS.PRINT_WORK_DAYS_ONLY' | translate }}" (change)="oneDayPage = $event" [checked]="oneDayPage"></vnc-checkbox>
            <vnc-checkbox *ngIf="printViewType === 'week'" label="{{ 'CALENDARS.PRINT_ONE_WEEK_PAGE' | translate }}" (change)="oneWeekPage = $event" [checked]="oneWeekPage"></vnc-checkbox>
            <vnc-checkbox label="{{ 'CALENDARS.INCLUDE_MINI_CALENDER_LBL' | translate }}" (change)="minicalendar = $event" [checked]="minicalendar"></vnc-checkbox>
        </div>
        <mat-menu #calendarMenu="matMenu" class="cal-dropdown-menu calendar-tree-menu">
            <span mat-menu-item class="search-input-item" (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation()">
                <vnc-input
                [form]="searchControl"
                [multiple]="false"
                [disableSearchFilterDropdown]="'Folder'"
                [searchFilterOptions]="searchFilterOptions"
                [searchFilterControl]="searchFilterControl"
                size="s" leadingIconName="mdi-magnify"
                [placeholder]="searchPlaceholder"
                [showLabel]="false" [showClear]="true"
                [type]="'simple-search'"
                [required]="true"></vnc-input>
            </span>
            <mat-tree [dataSource]="calendarDataSource" [treeControl]="calendarTreeControl"
            class="sidebar-tree-subfolders">
                <mat-tree-node mat-menu-item *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                    style="white-space:no-wrap;" class="sub_folder_tree_node" (click)="itemSelectionToggle(node, $event)">
                    <li *ngIf="node" class="sub_folder">
                        <div class="mat-tree-node">
                            <button mat-icon-button disabled></button>
                            <div class="folder-information">
                                <div class="folder-div disable-select">
                                    <!-- <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                        (change)="itemSelectionToggle(node, $event)" class="disable-select">
                                    </mat-checkbox> -->
                                    <span *ngIf="!node.perm">
                                        <span *ngIf="node.id!=='3'" class="folder-icon-block">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g id="Icon/product/folder-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Icon-24px" transform="translate(2.000000, 4.000000)" fill="#8B96A0">
                                                        <path [style.fill]="node.folderColor" d="M7.00922203,0 C7.49995184,0 8.18025928,0.262988878 8.59481703,0.605726148 L8.71075654,0.71075654 L9.28924346,1.28924346 C9.65129486,1.65129486 10.3113868,1.94247309 10.8421188,1.99242242 L10.9970301,2 L18,2 C19.05,2 19.9177686,2.82004132 19.9944872,3.85130541 L20,4 L20,14 C20,15.05 19.1799587,15.9177686 18.1486946,15.9944872 L18,16 L2,16 C0.95,16 0.082231405,15.1799587 0.00551277235,14.1486946 L0,14 L0.01,2 C0.01,0.95 0.820929752,0.082231405 1.85136551,0.00551277235 L2,0 L7.00922203,0 Z M7.00922203,2 L2.00999931,2.00166667 L2,14 L18,14 L18,4 L10.9970301,4 C9.98506984,4 8.81419501,3.54226902 8.03565409,2.85431634 L7.8750299,2.70345702 L7.29654298,2.1249701 L7.27022108,2.10994272 L7.0847011,2.02870664 L7.00922203,2 Z" id="Shape"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        <mat-icon *ngIf="node.id==='3'" class="mdi-16px disable-select"
                                            fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                                    </span>
                                    <span *ngIf="node.perm">
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            fontIcon="mdi-account-multiple" [style.color]="node.folderColor">
                                        </mat-icon>
                                    </span>
                                    <span class="sidebar-title disable-select"
                                        title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                        [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                        {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}
                                    </span>
                                    <span class="check-icon">
                                        <ng-container *ngIf="checklistSelection.isSelected(node)" [ngTemplateOutlet]="checkIcon"></ng-container>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
                <mat-tree-node mat-menu-item *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding
                    style="white-space:no-wrap;" class="sub_folder_tree_node" (click)="itemSelectionToggle(node, $event)">
                    <li *ngIf="node" class="sub_folder">
                        <div class="mat-tree-node">
                            <button *ngIf="calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                            <ng-container [ngTemplateOutlet]="matSelectTriggerIcon"></ng-container>
                                <!-- <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon> -->
                            </button>

                            <button class="mat-tree-arrow-icon" *ngIf="!calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.name">
                                <ng-container [ngTemplateOutlet]="arrowRightIcon"></ng-container>
                                <!-- <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon> -->
                            </button>
                            <div class="folder-information">
                                <!-- <div (click)="routeToFolder(node)" class="folder-div"> -->
                                <div class="folder-div disable-select">
                                    <!-- <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                        class="disable-select" (change)="itemSelectionToggle(node, $event)">
                                    </mat-checkbox> -->
                                    <span class="folder-icon-block" *ngIf="!node.perm && node.id !== '3'">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <g id="Icon/product/folder-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Icon-24px" transform="translate(2.000000, 4.000000)" fill="#8B96A0">
                                                    <path [style.fill]="node.folderColor" d="M7.00922203,0 C7.49995184,0 8.18025928,0.262988878 8.59481703,0.605726148 L8.71075654,0.71075654 L9.28924346,1.28924346 C9.65129486,1.65129486 10.3113868,1.94247309 10.8421188,1.99242242 L10.9970301,2 L18,2 C19.05,2 19.9177686,2.82004132 19.9944872,3.85130541 L20,4 L20,14 C20,15.05 19.1799587,15.9177686 18.1486946,15.9944872 L18,16 L2,16 C0.95,16 0.082231405,15.1799587 0.00551277235,14.1486946 L0,14 L0.01,2 C0.01,0.95 0.820929752,0.082231405 1.85136551,0.00551277235 L2,0 L7.00922203,0 Z M7.00922203,2 L2.00999931,2.00166667 L2,14 L18,14 L18,4 L10.9970301,4 C9.98506984,4 8.81419501,3.54226902 8.03565409,2.85431634 L7.8750299,2.70345702 L7.29654298,2.1249701 L7.27022108,2.10994272 L7.0847011,2.02870664 L7.00922203,2 Z" id="Shape"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"
                                        *ngIf="!node.perm && node.id === '3'"></mat-icon>
                                    <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                        [style.color]="node.folderColor" fontIcon="mdi-account-multiple"
                                        *ngIf="node.perm"></mat-icon>
                                        <span class="sidebar-title disable-select"
                                            title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                            [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                            {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}
                                        </span>
                                        <span class="check-icon">
                                            <ng-container *ngIf="checklistSelection.isSelected(node)" [ngTemplateOutlet]="checkIcon"></ng-container>
                                        </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
            </mat-tree>
            <vnc-footer *ngIf="!isMobileScreen" class="calendar-menu-action" [secondaryLabel]="'CANCEL' | translate | uppercase"
            [primaryLabel]="'APPLY_LBL' | translate | uppercase"
            [type]="'secondary-primary'"
            [color]="'white'"
            (onSecondaryClick)="cancel()"
            (onPrimaryClick)="updateSelectedCalendar()"></vnc-footer>
        </mat-menu>
    </div>
    <div *ngIf="isMobileScreen" class="left-right">
        <div class="left">
            <div class="calendar-lbl">
                {{ 'CALENDARS.CALENDARS' | translate }}
            </div>
            <cdk-virtual-scroll-viewport itemSize="10" class="folders-viewport">
                <ng-container *cdkVirtualFor="let item of calendarFolders"></ng-container>
                <mat-tree [dataSource]="calendarDataSource" [treeControl]="calendarTreeControl"
                    class="sidebar-tree-subfolders">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button mat-icon-button disabled></button>
                                <div class="folder-information">
                                    <div class="folder-div disable-select">
                                        <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                            (change)="itemSelectionToggle(node, $event)" class="disable-select">
                                        </mat-checkbox>
                                        <span *ngIf="!node.perm">
                                            <mat-icon *ngIf="node.id!=='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-calendar-blank"
                                                [style.color]="node.folderColor">
                                            </mat-icon>
                                            <mat-icon *ngIf="node.id==='3'" class="mdi-16px disable-select"
                                                fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                                        </span>
                                        <span *ngIf="node.perm">
                                            <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                                fontIcon="mdi-account-multiple" [style.color]="node.folderColor">
                                            </mat-icon>
                                        </span>
                                        <span class="sidebar-title disable-select"
                                            title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                            [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                            {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                    <mat-tree-node *matTreeNodeDef="let node; when: hasNestedChild" matTreeNodePadding
                        style="white-space:no-wrap;" class="sub_folder_tree_node">
                        <li *ngIf="node" class="sub_folder">
                            <div class="mat-tree-node">
                                <button *ngIf="calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                                </button>

                                <button *ngIf="!calendarTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                                </button>
                                <div class="folder-information">
                                    <!-- <div (click)="routeToFolder(node)" class="folder-div"> -->
                                    <div class="folder-div disable-select">
                                        <mat-checkbox [checked]="checklistSelection.isSelected(node)"
                                            class="disable-select" (change)="itemSelectionToggle(node, $event)">
                                        </mat-checkbox>
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            fontIcon="mdi-calendar-blank" [style.color]="node.folderColor"
                                            *ngIf="!node.perm && node.id !== '3'"></mat-icon>
                                        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-delete"
                                            *ngIf="!node.perm && node.id === '3'"></mat-icon>
                                        <mat-icon class="mdi-16px disable-select" fontSet="mdi"
                                            [style.color]="node.folderColor" fontIcon="mdi-account-multiple"
                                            *ngIf="node.perm"></mat-icon>
                                        <span class="sidebar-title disable-select"
                                            title="{{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}"
                                            [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                            {{mailService?.getFolderNameKey(node.name, CalendarConstants?.SYSTEM_FOLDERS, true)}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </mat-tree-node>
                </mat-tree>
            </cdk-virtual-scroll-viewport>
        </div>
        <div class="right">
            <div class="radio-select-division">
                <div class="radio-buttons">
                    <mat-radio-group [(ngModel)]="selectedRadio">
                        <div class="lbl-radio">{{ 'CALENDARS.SELCTED_DATE_LBL' | translate }}:</div>
                        <mat-radio-button value="currentDate">
                            <div>
                                <button class="cal-button-arrow-drop-down appt-btn" (click)="todayDate.open()"
                                    [disabled]="selectedRadio === 'dateRange'">
                                    <input class="cal-mat-date-picker-input" [matDatepicker]="todayDate"
                                        [formControl]="todayDateFormControl" />
                                    <span
                                        class="cal-mat-date-picker-span">{{ todayDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                    <mat-datepicker #todayDate [touchUi]=true></mat-datepicker>
                                </button>
                            </div>
                            <div>
                                <button mat-button [disabled]="selectedRadio === 'dateRange'"
                                    (click)="setTodayDateItem()" class="today-print-btn">
                                    {{ 'CALENDARS.TODAY' | translate }}
                                </button>
                            </div>
                        </mat-radio-button>
                        <div class="lbl-radio">{{ 'CALENDARS.DATE_RANGE_LBL' | translate }}:</div>
                        <mat-radio-button value="dateRange">
                            <div class="date-range-div">
                                <div>
                                    <button class="cal-button-arrow-drop-down appt-btn" (click)="startDate.open()"
                                        [disabled]="selectedRadio === 'currentDate'">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="startDate"
                                            [formControl]="startDateFormControl" />
                                        <span
                                            class="cal-mat-date-picker-span">{{ startDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #startDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                                <div>{{ 'CALENDARS.TO_TEXT_LBL' | translate }}</div>
                                <div>
                                    <button class="cal-button-arrow-drop-down appt-btn" (click)="endDate.open()"
                                        [disabled]="selectedRadio === 'currentDate'">
                                        <input class="cal-mat-date-picker-input" [matDatepicker]="endDate"
                                            [formControl]="endDateFormControl" />
                                        <span
                                            class="cal-mat-date-picker-span">{{ endDateFormControl.value | date: "LLL dd, yyyy": "": "en" }}</span>
                                        <mat-icon>arrow_drop_down</mat-icon>
                                        <mat-datepicker #endDate [touchUi]=true></mat-datepicker>
                                    </button>
                                </div>
                            </div>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="bottom-part">
                <div class="lable-item">
                    {{ 'CALENDARS.PRINT_VIEW_LBL' | translate }}:
                </div>
                <div class="print-view">
                    <button [matMenuTriggerFor]="printTypes" class="appt-btn">
                        <span *ngIf="printViewType === 'day'">{{ 'CALENDARS.DAY_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'week'">{{ 'CALENDARS.WEEK_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'month'">{{ 'CALENDARS.MONTH_VIEW_TITLE' | translate }}</span>
                        <span *ngIf="printViewType === 'list'">{{ 'CALENDARS.LIST_VIEW_TITLE' | translate }}</span>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                </div>
                <div class="lable-item">
                    {{ 'CALENDARS.HOURS_LBL' | translate }}:
                </div>
                <div class="hours-div" *ngIf="printViewType !=='month' && printViewType !=='list'">
                    <div>
                        <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="startTimePointMenu">
                            <span>{{ startTimeControl | date: "hh:mm a" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                    <div>
                        {{ 'CALENDARS.TO_TEXT_LBL' | translate }}
                    </div>
                    <div>
                        <button class="cal-button-arrow-drop-down appt-btn" [matMenuTriggerFor]="endTimePointMenu">
                            <span>{{ endTimeControl | date: "hh:mm a" }}</span>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="lable-item">
                    {{ 'PREFERENCES.OPTIONS' | translate }}:
                </div>
                <div class="options">
                    <mat-checkbox *ngIf="printViewType === 'day'" [(ngModel)]="oneDayPage">
                        {{ 'CALENDARS.PRINT_ONE_DAY_PAGE_LBL' | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="printViewType === 'week' || printViewType === 'month'"
                        [(ngModel)]="oneDayPage">
                        {{ 'CALENDARS.PRINT_WORK_DAYS_ONLY' | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="printViewType === 'week'" [(ngModel)]="oneWeekPage">
                        {{ 'CALENDARS.PRINT_ONE_WEEK_PAGE' | translate }}</mat-checkbox>
                    <mat-checkbox [(ngModel)]="minicalendar">
                        {{ 'CALENDARS.INCLUDE_MINI_CALENDER_LBL' | translate }}</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <mat-menu #printTypes="matMenu" class="cal-dropdown-menu print-view-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let option of viewTypeOptions" (click)="changeViewType(option)">
            <span *ngIf="option === 'day'">{{ 'CALENDARS.DAY_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'week'">{{ 'CALENDARS.WEEK_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'month'">{{ 'CALENDARS.MONTH_VIEW_TITLE' | translate }}</span>
            <span *ngIf="option === 'list'">{{ 'CALENDARS.LIST_VIEW_TITLE' | translate }}</span>
        </button>
    </mat-menu>
    <mat-menu #startTimePointMenu="matMenu" class="cal-dropdown-menu hours-mat-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.startTimePointOptions"
            (click)="handleStartTimeChanges(timepoint)">
            <span *ngIf="isMobileScreen">{{ timepoint | date: "hh:mm a" }}</span>
            <span *ngIf="!isMobileScreen">{{ timepoint | date: "hh:mm" }}</span>
            <span style="color: #7a858f;" *ngIf="!isMobileScreen">{{ timepoint | date: "a" }}</span>
        </button>
    </mat-menu>
    <mat-menu #endTimePointMenu="matMenu" class="cal-dropdown-menu hours-mat-menu">
        <button vpAutoFocus mat-menu-item *ngFor="let timepoint of calendarComposeView.endTimePointOptions"
            (click)="handleEndTimeChanges(timepoint)">
            <span *ngIf="isMobileScreen">{{ timepoint | date: "hh:mm a" }}</span>
            <span *ngIf="!isMobileScreen">{{ timepoint | date: "hh:mm" }}</span>
            <span style="color: #7a858f;" *ngIf="!isMobileScreen">{{ timepoint | date: "a" }}</span>
        </button>
    </mat-menu>
    <mat-divider *ngIf="isMobileScreen"></mat-divider>
    <vnc-footer *ngIf="!isMobileScreen" class="print-dialog-footer" [secondaryLabel]="'CANCEL' | translate | uppercase"
    [primaryLabel]="'CALENDARS.PRINT' | translate | uppercase"
    [type]="'secondary-primary'"
    [color]="'white'"
    (onSecondaryClick)="close()"
    (onPrimaryClick)="printCalendar()"></vnc-footer>
    <div class="mobile-footer">
        <span>{{ 'CALENDARS.PRINT_CALENDAR_LBL' | translate }}</span>
        <button mat-button (click)="printCalendar()">
            <mat-icon class="disable-select">print</mat-icon>
        </button>
    </div>
</div>

<ng-template #closeIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
      </g>
    </svg>
</ng-template>

<ng-template #matSelectTriggerIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/keyboard-arrow-down-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M7.25735931,9.79289322 C7.6478836,9.40236893 8.28104858,9.40236893 8.67157288,9.79289322 L12.5,13.6213203 L16.3284271,9.79289322 C16.7189514,9.40236893 17.3521164,9.40236893 17.7426407,9.79289322 C18.133165,10.1834175 18.133165,10.8165825 17.7426407,11.2071068 L12.5,16.4497475 L7.25735931,11.2071068 C6.86683502,10.8165825 6.86683502,10.1834175 7.25735931,9.79289322 Z" id="Path" fill="#8B96A0"></path>
      </g>
    </svg>
</ng-template>
<ng-template #arrowRightIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/keyboard-arrow-right-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M9.29289322,7.29289322 C8.90236893,7.68341751 8.90236893,8.31658249 9.29289322,8.70710678 L13.1213203,12.5355339 L9.29289322,16.363961 C8.90236893,16.7544853 8.90236893,17.3876503 9.29289322,17.7781746 C9.68341751,18.1686989 10.3165825,18.1686989 10.7071068,17.7781746 L15.9497475,12.5355339 L10.7071068,7.29289322 C10.3165825,6.90236893 9.68341751,6.90236893 9.29289322,7.29289322 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>
<ng-template #folderIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/folder-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(2.000000, 4.000000)" fill="#8B96A0">
                <path d="M7.00922203,0 C7.49995184,0 8.18025928,0.262988878 8.59481703,0.605726148 L8.71075654,0.71075654 L9.28924346,1.28924346 C9.65129486,1.65129486 10.3113868,1.94247309 10.8421188,1.99242242 L10.9970301,2 L18,2 C19.05,2 19.9177686,2.82004132 19.9944872,3.85130541 L20,4 L20,14 C20,15.05 19.1799587,15.9177686 18.1486946,15.9944872 L18,16 L2,16 C0.95,16 0.082231405,15.1799587 0.00551277235,14.1486946 L0,14 L0.01,2 C0.01,0.95 0.820929752,0.082231405 1.85136551,0.00551277235 L2,0 L7.00922203,0 Z M7.00922203,2 L2.00999931,2.00166667 L2,14 L18,14 L18,4 L10.9970301,4 C9.98506984,4 8.81419501,3.54226902 8.03565409,2.85431634 L7.8750299,2.70345702 L7.29654298,2.1249701 L7.27022108,2.10994272 L7.0847011,2.02870664 L7.00922203,2 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>
<ng-template #checkIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="Shape" fill="#ffffff" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
      </g>
  </svg>
  </ng-template>