
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { Observable, Subject, throwError } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../../..//environments/environment";
import { map, take } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { MailConstants } from "../utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { MailBroadcaster } from "./mail-broadcaster.service";
import { IdbUtils } from "src/app/services/idb.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { AppService } from "src/app/services/app.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/reducers/app";
import { Logout } from "src/app/actions/app";
import { ResetMailFolderState, ResetConversationState } from "src/app/mail/store/actions";
import { ResetPreferenceState } from "src/app/preference/store/actions";
import { DatabaseService } from "../../services/db/database.service";

@Injectable()
export class AuthService {

  private AUTH_TOKEN_KEY: string;
  userRequest: any = null;
  redirectTo: string = null;
  isProfileUpdated$: Subject<boolean> = new Subject<boolean>();
  checkAuthenticationInterval: any;

  authUserChanged$ = new Subject<boolean>();

  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private config: ConfigService,
    private mailBroadcaster: MailBroadcaster,
    private http: Http,
    private appService: AppService,
    private router: Router,
    private store: Store<AppState>,
    private databaseService: DatabaseService,
    private electronService: ElectronService) {
    this.AUTH_TOKEN_KEY = this.config.get("AUTH_TOKEN_KEY");
  }

  public getProfile() {
    let headers = new Headers({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new Headers({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.config.API_URL + "/api/profile", { headers: headers }).pipe(map(response => response.json()));
  }

  logout() {
    return this.http.get(this.config.API_URL + "/api/call-logout", {});
  }

  addAuthHeader(headers: Headers): Headers {
    // Appends an authentication header to headers
    const authorization = this.electronService.isElectron
        ? this.electronService.getFromStorage(this.AUTH_TOKEN_KEY)
        : localStorage.getItem(this.AUTH_TOKEN_KEY);
    if (authorization) {
      headers.append("Authorization", "Bearer " + authorization);
    }
    return headers;
  }

  cordovaLogout(): Observable<any> {
    return this.http.get(this.config.API_URL + `/api/cordova-logout`);
  }

  delAuthToken() {
    if (!this.checkAuthenticationInterval) {
      clearInterval(this.checkAuthenticationInterval);
    }

    let serverUrl = null;
    let language = "en";
    if (CommonUtils.isOfflineModeSupported()) {
      IdbUtils.clearAll();
    }
    sessionStorage.clear();
    if (environment.isCordova || environment.isElectron) {
      if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
        window.FirebasePlugin.unregister();
      }
      serverUrl = localStorage.getItem("serverURL");
      language = localStorage.getItem(MailConstants.LANGUAGE_KEY) || "en";
      localStorage.clear();
      localStorage.setItem("serverURL", serverUrl);
      localStorage.setItem(MailConstants.LANGUAGE_KEY, language);
      if (this.electronService.isElectron) {
        this.electronService.clearStorage();
        this.electronService.setToStorage("serverURL", serverUrl);
        this.electronService.setToStorage(MailConstants.LANGUAGE_KEY, language);
      }
    } else {
      language = localStorage.getItem(MailConstants.LANGUAGE_KEY) || "en";
      localStorage.clear();
      localStorage.setItem(MailConstants.LANGUAGE_KEY, language);
    }
  }

  isLogin(): boolean {
    const zimbraUser = this.electronService.isElectron
      ? this.electronService.getFromStorage(MailConstants.ZIMBRA_USER)
      : localStorage.getItem(MailConstants.ZIMBRA_USER);
    return !!zimbraUser;
  }

  removeLogin(redirect?: boolean): void {
    this.delAuthToken();
  }

  getAuthToken(): string {
    return localStorage.getItem("token");
  }

  handleErrorObservable(error: Response | any) {
    console.log("[handleErrorObservable] Error", error);
    if (error.error && (error.error.msg === MailConstants.TOKENERROR || error.error.msg === MailConstants.AUTH_CREDENTIALS_HAVE_EXPIRED)) {
      this.mailBroadcaster.broadcast("generateNewToken", new Date().getMinutes());
    }
    if (error.error && error.error.msg) {
      return throwError(error.error.msg);
    } else if (error._body && MailUtils.isJson(error._body)) {
      const body = JSON.parse(error._body);
      console.log("[handleErrorObservable]", body);
      if (body.msg) {
          if (body.msg.indexOf("contact must have fields") !== -1) {
              return throwError("CONTACT_MUST_HAVE_FIELDS");
          } else {
              return throwError(body.msg);
          }
      } else {
          return throwError(error);
      }
    } else if (error.error && error.error instanceof String) {
      if ( error.error.indexOf("RangeError: Maximum call stack size exceeded") !== -1) {
        return throwError("RangeError: Maximum call stack size exceeded");
      } else {
        return throwError(error.error);
      }
    } else {
      return throwError(error);
    }
  }

  logoutWebAndApp() {
    console.log("[logoutWebAndApp]");

    if (environment.isCordova) { // request is sent to backend before we remove the token
      if (typeof window.FirebasePlugin !== "undefined") {
        window.FirebasePlugin.unregister();
        window.FirebasePlugin.clearAllNotifications(() => {
          console.log("[logoutWebAndApp][FirebasePlugin clearAllNotifications] success");
        }, error => {
            console.error("[logoutWebAndApp][FirebasePlugin clearAllNotifications] error", error);
        });
        try {
          ClearData.cache(() => {
            console.log("clearData done");
          }, err => {
            console.error("error clearData: ", err);
          });
        } catch (error) {
          console.log("error in clearData: ", error);
        }
      }
      this.appService.setFirebaseToken().subscribe(res => {
        console.log("removed firebase token");
      });
    }
    this.removeLogin();
    this.store.dispatch(new Logout());
    console.log("[logoutWebAndApp] going to clearDB!");

    this.databaseService.deleteDB().pipe(take(1)).subscribe((res) => {
      console.log("[logoutWebAndApp] clearDB res: ", res);
    });

    if (environment.isElectron) {
      this.databaseService.clearDB().pipe(take(1)).subscribe((res) => {
        console.log("[logoutWebAndApp] clearDB res: ", res);
      });
    }

    this.store.dispatch(new ResetMailFolderState());
    this.store.dispatch(new ResetConversationState());
    this.store.dispatch(new ResetPreferenceState());
    if (this.isCordovaOrElectron) {
      this.cordovaLogout().subscribe(res => {
        console.log("[cordovaLogout]");
      });
      const currentUrl = this.router.routerState.snapshot.url;
      window.location.href = CommonUtils.getBaseUrl() + "/index.html";
      this.config.loginIframe(currentUrl);
    } else {
      window.location.href = "/api/call-logout";
    }
  }

}
