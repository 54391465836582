
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, OnDestroy, OnChanges } from "@angular/core";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { take } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MailService } from "../../services/mail-service";
import { ToastService } from "src/app/common/providers/toast.service";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { TranslateService } from "@ngx-translate/core";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { MatDialogRef } from "@angular/material/dialog";
import { SharedUtils } from "src/app/mail/utils/shared.utils";
import { isArray } from "util";

@Component({
    selector: "vp-attach-contact-dialog",
    templateUrl: "./attach-contact-dialog.component.html"
})
export class AttachContactDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    allFolders: MailFolder[] = [];
    offset: number = 0;
    limit: number = 50;
    selectedFolder: MailFolder;
    isMoreContact: boolean = false;
    contacts: any[] = [];
    selectedContacts: any[] = [];
    searchField: string = "";
    treeControl = new NestedTreeControl<MailFolder>(node => node.children);
    dataSource = new MatTreeNestedDataSource<MailFolder>();
    isLoading: boolean = false;
    hasChild = (_: number, node: MailFolder) => !!node.children && node.children.length > 0;
    MailConstants = MailConstants;
    constructor(
        public dialogRef: MatDialogRef<AttachContactDialogComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone,
        private ngxHotKeyService: NgxHotkeysService,
        private mailService: MailService,
        private toastService: ToastService,
        private convRepository: ConversationRepository,
        private translate: TranslateService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ATTACH_EMAIL_DIALOG).pipe(take(1)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.getContactFolders();
    }

    getContactFolders(): void {
        this.isLoading = true;
        const body = { view: "contact" };
        this.mailService.getContactFolders(body).pipe(take(1)).subscribe(res => {
            this.isLoading = false;
            console.log("[getContactFolders]: ", res);
            if (!!res && res.folder) {
                let folders = SharedUtils.parseFolders(res, false);
                folders = folders.filter(v => !!v).filter(v => v.absFolderPath !== "/starred");
                if (!!folders && folders !== null) {
                    this.allFolders = folders;
                    this.dataSource.data = this.allFolders.filter(f => f.absFolderPath !== "/starred");
                    this.treeControl.dataNodes = this.allFolders;
                    this.treeControl.expandAll();
                    this.selectedFolder = this.allFolders.filter(f => f.id === "7")[0];
                    this.getContactByFolder(this.selectedFolder);
                }
            }
        }, err => {
            this.isLoading = false;
            this.toastService.showPlainMessage(err);
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    close(ids?: any) {
        this.dialogRef.close({ ids: ids });
    }

    folderItem(node: MailFolder): void {
        this.offset = 0;
        this.limit = 50;
        this.selectedFolder = node;
        this.getContactByFolder(node);
    }

    loadPreviousContact(): void {
        this.offset -= this.limit;
        this.getContactByFolder(this.selectedFolder);
    }

    loadNextContact(): void {
        this.offset += this.limit;
        this.getContactByFolder(this.selectedFolder);
    }

    getContactByFolder(node: MailFolder): void {
        this.selectedContacts = [];
        if (node !== null) {
            const query = "inid:" + "\"" + node.id + "\"";
            this.getAllContacts(query);
        } else {
            this.getAllContacts(this.searchField);
        }
    }

    getAllContacts(query: string): void {
        this.isLoading = true;
        const searchQuery: any = {
            fetch: 1,
            limit: this.limit,
            needExp: 1,
            offset: this.offset,
            query: query, // + " not #type:group",
            types: "contact"
        };
        this.mailService.searchRequest(searchQuery).pipe(take(1)).subscribe(contacts => {
            this.isLoading = false;
            const allContacts = contacts;
            this.contacts = [];
            if (!!allContacts && allContacts.cn) {
                const parseFolders = allContacts.cn.filter(c => !c.m);
                parseFolders.map(c => {
                    let isAddContact: boolean = true;
                    const contactItem: any = {
                        fullName: "",
                        email: "",
                        firstLastCharacter: "N/A",
                        id: ""
                    };
                    contactItem.id = c.id;
                    const firstLastName: any[] = [];
                    if (c._attrs && c._attrs.firstName) {
                        const firstName = isArray(c._attrs.firstName) ? c._attrs.firstName[0] : c._attrs.firstName;
                        firstLastName.push(firstName);
                    }
                    if (c._attrs && c._attrs.firstname) {
                        const firstName = isArray(c._attrs.firstname) ? c._attrs.firstname[0] : c._attrs.firstname;
                        firstLastName.push(firstName);
                    }
                    if (c._attrs && c._attrs.lastName) {
                        const lastName = isArray(c._attrs.lastName) ? c._attrs.lastName[0] : c._attrs.lastName;
                        firstLastName.push(lastName);
                    }
                    if (c._attrs && c._attrs.lastname) {
                        const lastName = isArray(c._attrs.lastname) ? c._attrs.lastname[0] : c._attrs.lastname;
                        firstLastName.push(lastName);
                    }
                    if (!!firstLastName && firstLastName !== null && firstLastName.length > 0) {
                        contactItem.fullName = firstLastName.join(", ").toString();
                    }
                    if (!!contactItem.fullName && contactItem.fullName !== "") {
                        contactItem.firstLastCharacter = contactItem.fullName.charAt(0);
                    }
                    if (c._attrs && c._attrs.email) {
                        contactItem.email = isArray(c._attrs.email) ? c._attrs.email[0] : c._attrs.email;
                        if (contactItem.firstLastCharacter === "N/A") {
                            contactItem.firstLastCharacter = contactItem.email.charAt(0);
                        }
                    }
                    if (c._attrs && c._attrs.type && c._attrs.type === "group") {
                        isAddContact = false;
                    }
                    if (contactItem.firstLastCharacter === "N/A") {
                        isAddContact = false;
                    }
                    if (isAddContact) {
                        this.contacts.push(contactItem);
                    }
                });
                if (contacts.more) {
                    this.isMoreContact = true;
                } else {
                    this.isMoreContact = false;
                }
            }
        }, error => {
            this.isLoading = false;
            this.toastService.showPlainMessage(error);
        });
    }

    getAvatar(email) {
        return this.convRepository.getAvatar(email);
    }

    selectContact(contact: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedContacts.indexOf(contact) === -1) {
            if ($event.ctrlKey) {
                this.selectedContacts.push(contact);
            } else {
                this.selectedContacts = [];
                this.selectedContacts.push(contact);
            }
        } else {
            if (this.selectedContacts.length > 1) {
                this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
            }
        }
    }


    isSelectedEmailContacts(email: any): boolean {
        return this.selectedContacts.indexOf(email) !== -1;
    }

    attachContacts(): void {
        const ids = this.selectedContacts.map(m => m.id);
        this.close(ids);
    }

    searchContacts(): void {
        this.offset = 0;
        this.limit = 50;
        this.selectedFolder = null;
        this.getAllContacts(this.searchField);
    }
}
