
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component , OnInit, OnDestroy, Input, Output , OnChanges} from "@angular/core";


@Component({
  selector: "vp-strip-progress",
  templateUrl: "./strip-progress.html",
  styleUrls: ["./strip-progress.scss"],
})
export class VNCStripProgress implements OnInit, OnDestroy, OnChanges {
  @Input() showProgressBar :boolean = false;
  @Output() hideProgressBar :boolean = false;
  @Input() progressClass = "view-strip";
  count:number = 0;

  constructor(
  ) {}


  ngOnInit() {
  }

  ngOnChanges(changes): void {
    console.log("ngOnChanges changes", changes);
    // if (this.count > 0) {
    //   if (this.showProgressBar) {
    //     this.progressClass = "visible-v";
    //     setTimeout(() => {
    //       this.progressClass = "view-strip";
    //     }, 10);
    //   } else {
    //     this.progressClass = "hide-strip";
    //     setTimeout(() => {
    //       this.progressClass = "hide-v";
    //     }, 600);
    //   }
    // }
    // this.count = 1;
    // this.count++;
  }

  ngOnDestroy() {
    this.progressClass = "hide-strip";
  }

}
