
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import {
    PreferenceRootState,
    getPreferences,
    getIsPreferencesLoading,
    getPreferencesByIds,
    getPreferenceById,
    getSignatures,
    getSelectedAccount,
    getIdentity,
    getAttributes} from "../store/selectors";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { Preference, Signature, DataSource } from "../shared/models";
import { SearchRequest } from "src/app/mail/shared/models";
import { SetQueryAction } from "src/app/mail/store/actions";
import {
    IsPreferenceLoadingAction,
    LoadPreferenceSuccessAction,
    LoadPreferenceFailedAction,
    RemovePreferencesAction,
    ResetSignatures,
    RemoveSignatures,
    UpdateSignatures,
    AddSignature,
    UpdateManyPreferencesSuccess,
    SelectAccount,
    SetIdentityAction
} from "../store/actions";
import { Identity } from "../shared/models/identity.model";
import { Attribute } from "../shared/models/attribute.model";
import { filter, take } from "rxjs/operators";
import { ConfigService } from "src/app/config.service";
import { SetPollingInterval } from "src/app/actions/app";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { getOnlineStatus } from "src/app/reducers";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PreferenceService } from "../shared/services/preference.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { DatabaseService } from "src/app/services/db/database.service";
import { CommonUtils } from "src/app/common/utils/common-util";

@Injectable()
export class PreferenceRepository {

    isOnline = false;

    constructor(
        private configService: ConfigService,
        private localStorageService: LocalStorageService,
        private preferenceService: PreferenceService,
        private dbSrvice: DatabaseService,
        private store: Store<PreferenceRootState>) {

        this.store.select(getOnlineStatus).subscribe(res => {
          this.isOnline = res;
        });
    }

    getPreferences(): Observable<Preference[]> {
        return this.store.select(getPreferences);
    }

    getPreferencesByIds(ids: string[]): Observable<Preference[]> {
        return this.store.pipe(select(state => getPreferencesByIds(state, ids)), filter(v => !!v));
    }

    getPreferenceById(id: string): Observable<Preference> {
        return this.store.select(state => getPreferenceById(state, id));
    }

    getSelectedAccount(): Observable<DataSource> {
        return this.store.select(getSelectedAccount);
    }

    getIdentity(): Observable<Identity> {
        return this.store.select(getIdentity);
    }

    getAttributes(): Observable<Attribute[]> {
        return this.store.select(getAttributes);
    }

    selectAccount(account: DataSource): void {
        return this.store.dispatch(new SelectAccount(account));
    }

    setQuery(query: SearchRequest): void {
        this.store.dispatch(new SetQueryAction({ query }));
    }

    setPreferenceIsLoading(): void {
        this.store.dispatch(new IsPreferenceLoadingAction());
    }

    getIsPreferencesLoading(): Observable<boolean> {
        return this.store.select(getIsPreferencesLoading);
    }

    setPreferenceLoadedSuccess(preferences: Preference[]): void {
        this.store.dispatch(new LoadPreferenceSuccessAction(preferences));
    }

    setIdentity(identity: Identity): void {
        this.store.dispatch(new SetIdentityAction(identity));
    }

    setPreferenceLoadedFail(): void {
        this.store.dispatch(new LoadPreferenceFailedAction());
    }

    removePreferences(): void {
        this.store.dispatch(new RemovePreferencesAction());
    }

    updatePreferences(preferences: Preference[]): void {
        this.store.dispatch(new UpdateManyPreferencesSuccess(preferences));
        const config: any = {};
        preferences.forEach(pref => {
          config[pref.key] = pref.value;
          if (pref.key === "zimbraPrefMailPollingInterval") {
            this.store.dispatch(new SetPollingInterval(MailUtils.getPollingInterval(pref.value, 3000)));
          }
        });
        this.configService.setPreferences(config);
    }


    ///

    getSignatures(): Observable<Signature[]> {
        return this.store.select(getSignatures);
    }

    updateSignatures(signatures: Signature[]): void {
        this.store.dispatch(new UpdateSignatures(signatures));
    }

    addSignature(signature: Signature): void {
        this.store.dispatch(new AddSignature(signature));
    }

    removeSignatures(ids: string[]): void {
        this.store.dispatch(new RemoveSignatures(ids));
    }

    resetSignatures(): void {
        this.store.dispatch(new ResetSignatures());
    }


    getSignatureId(prefrenceName: string): Observable<string> {
      const response = new BehaviorSubject<string>(null);

      if (this.isOnline) {
        this.preferenceService.getSignatureId(prefrenceName).subscribe(
          res => {
            const signatureId = res._attrs ? res._attrs[prefrenceName] : null;
            if (signatureId) {
              this.localStorageService.setSignatureIdForPrefName(prefrenceName, signatureId);
            }

            response.next(signatureId);
            response.complete();
          }, error => {
            console.error("[PreferenceRepository][getSignatureId]", error);
            response.error(error);
            response.complete();
          });
      } else {
        const signatureId = this.localStorageService.getSignatureIdForPrefName(prefrenceName);
        response.next(signatureId);
        response.complete();
      }

      return response.asObservable();
    }

    modifySignaturePrefs(changes: Preference[]): Observable<any> {
      const response = new Subject<any>();

      this.preferenceService.modifySignaturePrefs(changes).subscribe(res => {

        // if any signature ids are cnahged - reflect it to local storage
        changes.forEach(c => {
          if ([MailConstants.MAIL_DEFAULT_SIGNATURE_PREF_NAME, MailConstants.MAIL_FWD_REPLY_SIGNATURE_PREF_NAME].includes(c.key)) {
            this.localStorageService.setSignatureIdForPrefName(c.key, c.value);
          }
        });

        response.next(res);
      }, error => {
        console.error("[PreferenceRepository][modifySignaturePrefs]", error);
        response.error(error);
      });

      return response.asObservable().pipe(take(1));
    }

    modifySignature(signature, htmlContent: string, plainContent: string, contactcid: string): Observable<any> {
      const response = new Subject<any>();

      this.preferenceService.modifySignature(signature, htmlContent, plainContent, contactcid).subscribe(res => {
        this.dbSrvice.updateSignature(signature).subscribe(ok => {
          response.next(res);
        });
      });

      return response.asObservable().pipe(take(1));
    }

    deleteSignatures(ids: string[]): Observable<any> {
      const response = new Subject<any>();

      this.preferenceService.deleteSignatures(ids).subscribe(res => {
        this.dbSrvice.deleteSignatures(ids).subscribe(ok => {
          response.next(res);
        });
      });

      return response.asObservable().pipe(take(1));
    }

    createSignature(name: string, htmlContent: string, plainContent: string, contactCid: string): Observable<any> {
      const response = new Subject<any>();

      this.preferenceService.createSignature(name, htmlContent, plainContent, contactCid).subscribe(res => {
        if (res.CreateSignatureResponse && res.CreateSignatureResponse[0].signature) {
          let content = plainContent;
          let type = "text/plain";
          if (htmlContent && htmlContent.length > 0) {
            content = htmlContent;
            type = "text/html";
          }

          const signature: Signature = {
            id: res.CreateSignatureResponse[0].signature[0].id,
            name: name,
            content: content,
            type: type
          };

          if (contactCid) {
            signature.cid = contactCid;
          }

          this.dbSrvice.createSignature(signature).subscribe(ok => {
            response.next(res);
          });
        } else {
          response.next(res);
        }
      });

      return response.asObservable().pipe(take(1));
    }

    retrieveSignatures(onlinePrefs = false): Observable<Signature[]> {
      const response = new Subject<Signature[]>();

      if (this.isOnline) {
        this.preferenceService.getSignatures().subscribe(async signatures => {
          if (onlinePrefs) {
            response.next(signatures);
          } else {
              const s = (!!signatures && (signatures.length > 0)) ? signatures : [];
              const sigs = s.filter(s => (!!s.content && (s.content.indexOf("<img src=\"http") === -1)));
              const sigsWithImg = s.filter(s => (!!s.content && (s.content.indexOf("<img src=\"http") > -1)));
              if (sigsWithImg.length > 0) {
                let updateSigRequests = [];
                sigsWithImg.forEach(s => {
                  updateSigRequests.push(this.replaceImagesInSignature(s));
                });
                Promise.all(updateSigRequests).then(results => {
                  console.log("sigsWithImg updated: ", results);
                  const updatedSigs = sigs.concat(results);
                  this.dbSrvice.setSignatures(updatedSigs).subscribe(res => {
                    response.next(updatedSigs);
                  });
                })
              } else {

                this.dbSrvice.setSignatures(signatures).subscribe(res => {
                  response.next(signatures);
                });

            }
          }
        });
      } else {
        this.dbSrvice.getSignatures().subscribe(signatures => {
          response.next(signatures);
        });
      }

      return response.asObservable().pipe(take(1));
    }

  replaceImagesInSignature(sig): Promise<any> {
    return new Promise(async resolve => {
      let updatedSig = { ...sig };
      const parser = new DOMParser();
      const content = MailUtils.replaceSignatureImageURL(sig.content);
      const htmlDoc = parser.parseFromString(content, "text/html");
      const img = htmlDoc.getElementsByTagName("img");
      for (let i = 0; i < img.length; i++) {
        let url = img[i].getAttribute("src");
        if ((url.startsWith("http://")) || (url.startsWith("https://"))) {
          let dataUrl = await this.getBase64dataForImg(url);
          if (dataUrl !== url) {
            img[i].setAttribute("src", dataUrl);
          }
        }
      }
      // console.log("sigsWithImg htmlDoc: ", sig, htmlDoc.documentElement);
      updatedSig.content = htmlDoc.documentElement.innerHTML;
      resolve(updatedSig);
    });
  }

  getBase64dataForImg(url): Promise<string> {
    return new Promise(resolve => {
      CommonUtils.getBase6ImageFromUrl(url).subscribe((dataurl: string) => {
        // console.log("SIGIMAGE2 dataurl: ", url, dataurl);
        resolve(dataurl);
      }, (err) => {
        console.log("SIGIMAGE2 dataurl err: ", err);
        resolve(url);
      });
    });
  }

}
