
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, OnDestroy, TemplateRef } from "@angular/core";
import { DateAdapter } from "../date-adapters/date-adapter";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Store } from "@ngrx/store";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { RootState } from "src/app/reducers";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { getTagById } from "src/app/mail/store/selectors";
import { take, takeUntil } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { Subject } from "rxjs";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import * as _ from "lodash";


@Component({
  selector: "vp-calendar-appointment-title",
  template: `
    <ng-template #defaultTemplate let-event="event" let-view="view" let-locale="locale">
      <span style="display: flex;">
        <mat-icon *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length === 1" [style.color]="getTagColorByNames(event?.tn)"
          style="vertical-align: middle; font-size: 14px; height: 16px; width: 16px;"
          class="event-tag-item">local_offer</mat-icon>
        <img *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length > 1" [src]="rainbowTagIcon" class="rainbow-colored-tag tag-sm">
        <mat-icon *ngIf="event.class && event.class==='PRI'" class="private-event-icon">lock</mat-icon>
        <div *ngIf="event.title !== ''"
          class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()" [class.titleshow]="event.tn && event.tn !== '' && event.tn.split(',').length > 1"
          [innerHTML]="event.title | vpCalendarAppointmentTitle: view:event" [class.event-font-color]="isLightColor(event.bgcolor)">
        </div>
        <div *ngIf="event.title === ''"
          class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()">
          {{ noTitle }}
        </div>
      </span>
      <span *ngIf="view !== 'month' && event.location !== ''"
        class="cal-event-location" [class.display-inline]="checkTimeSpanUnderAnHour()"
        [class.event-font-color]="isLightColor(event.bgcolor)">
        <a
        *ngIf="!!event && !!event.location && event?.location?.indexOf('/assets/meeting') !== -1 && !pastCheck()"
        [href]="event.location"
        target="_blank"
        class="join-meeting open-new-window">{{ 'JOIN' | translate }}</a>
        <ng-container *ngIf="event?.location?.indexOf('/assets/meeting') === -1">{{ event.location }}</ng-container>
      </span>
      <span *ngIf="sampleAppointment && view !== 'month' && !event.allDay" class="cal-event-title-time resizing-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
      [class.event-font-color]="isLightColor(sampleAppointment.bgcolor)">
        <span *ngIf="locale === 'en'">
          <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'hh:mm aaa': '': locale"></span> -
          <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'hh:mm aaa': '': locale"></span>
        </span>
        <span *ngIf="locale === 'de'">
          <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'HH:mm': '': locale"></span> -
          <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'HH:mm': '': locale"></span>
        </span>
      </span>
      <span *ngIf="view !== 'month' && !event.allDay" class="cal-event-title-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
      [class.event-font-color]="isLightColor(event.bgcolor)">
        <span *ngIf="locale === 'en'">
          <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'hh:mm aaa': '': locale"></span> -
          <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'hh:mm aaa': '': locale"></span>
        </span>
        <span *ngIf="locale === 'de'">
          <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'HH:mm': '': locale"></span> -
          <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'HH:mm': '': locale"></span>
        </span>
      </span>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        event: event,
        view: view,
        locale:locale
      }"
    >
    </ng-template>
  `
})
export class CalendarAppointmentTitleComponent implements OnDestroy {
  @Input() event: any;

  @Input() customTemplate: TemplateRef<any>;

  @Input() view: string;

  noTitle: string = "";

  locale: string = "en";
  rainbowTagIcon = CommonUtils.getFullUrl("/assets/images/icon-rainbow-tag.svg", "calendar");
  sampleAppointment: any;
  allZimbraTags: MailTag[] = [];

  private isAlive$ = new Subject<boolean>();
  constructor(
    protected dateAdapter: DateAdapter,
    protected translate: TranslateService,
    private store: Store<CalendarState | RootState>,
    private broadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private electronService: ElectronService
  ) {
    this.noTitle = this.translate.instant("CALENDARS.NO_TITLE");
    const language =  this.electronService.isElectron
    ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (!!language && language !== null) {
      this.locale = language;
    } else {
      this.locale = "en";
    }
    // console.log("calendarAppointmentTitle event: ", this.event);
    this.broadcaster.on<any>("appointmentTimeChanged").pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (v && v.event.eventId === this.event.eventId) {
        this.sampleAppointment = {};
        this.sampleAppointment.start = v.newStart;
        this.sampleAppointment.end = v.newEnd;
      }
    });
    this.broadcaster.on<any>("appointmentTimeEnded").pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (v && v.event.eventId === this.event.eventId) {
        this.sampleAppointment = null;
      }
    });
    this.commonRepository.getZimbraTagsList().pipe(takeUntil(this.isAlive$)).subscribe(allTags => {
      this.allZimbraTags = allTags;
    });

  }

  pastCheck(): boolean {
    return new Date()>this.event.end;
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  checkTimeSpanUnderAnHour(): boolean {
    let timeSpan = 0;
    if (this.event.end && this.event.start) {
      timeSpan = this.dateAdapter.differenceInMinutes(this.event.end, this.event.start);
    }

    return timeSpan <= 30;
  }

  isLightColor(color: string): boolean {
    // if (!!color && color !== "" && color !== null) {
    //   const isDark = CalenderUtils.lightOrDark(color) === "dark";
    //   if (isDark) {
    //       return false;
    //   } else {
    //       return true;
    //   }
    // }
    // return false;
    return true;
  }

  getTagColor(ids: string): string {
    let color = MailConstants.DEFAULT_COLOR;
    if (ids) {
      const tags = ids.split(",");
      this.store.select(state => getTagById(state, tags[0])).pipe(take(1)).subscribe(t => {
        if (!!t) {
          if (t.color && MailConstants.COLOR_LIST[t.color]) {
            t.color = MailConstants.COLOR_LIST[t.color];
          }
          if (t.rgb) {
            t.rgb = t.rgb.toLowerCase();
            if (t.rgb === "#999") {
              t.rgb = "#999999";
            }
          }
          color = t.rgb || t.color || color;
        }
      });
    }
    return color;
  }

  getTagColorByNames(names: string): string {
    let color = MailConstants.DEFAULT_COLOR;
    if (names) {
      const tagNames = names.split(",");
      const tags = this.allZimbraTags.filter(tt => tt.name.toLowerCase() === tagNames[0].toLowerCase());
      if (!!tags && !!tags[0]) {
        // console.log("getTagColorByNames tags: ", tags);
        const t = tags[0];
        if (t.color && MailConstants.COLOR_LIST[t.color]) {
          t.color = MailConstants.COLOR_LIST[t.color];
        }
        if (t.rgb) {
          t.rgb = t.rgb.toLowerCase();
          if (t.rgb === "#999") {
            t.rgb = "#999999";
          }
        }
        color = t.rgb || t.color || color;

      }
    }
    return color;
  }


}
