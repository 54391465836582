
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, SEMICOLON, FF_SEMICOLON, SPACE } from "@angular/cdk/keycodes";
import {
  Component, OnInit, ViewChild, ElementRef, Input,
  ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, OnDestroy, Output, EventEmitter
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, takeUntil, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { isArray } from "util";
import { Subject, of } from "rxjs";
import { Utils } from "../../../common";
import { ConversationRepository } from "../../../mail/repositories/conversation.repository";
import { MailService } from "../../../mail/shared/services/mail-service";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { MailConstants } from "../../../common/utils/mail-constants";
import { EmailInformation } from "../../../mail/shared/models";
import { DragDropParticipantService } from "src/app/mail/shared/services/drag-drop-participant.service";
import * as _ from "lodash";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MatAutocompleteTrigger, MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { RootState, getAllUserContacts, getAllUserGalContacts, getOnlineStatus } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ContextMenuComponent, ContextMenuService } from "ngx-contextmenu";
import { ContextMenuFixService } from "../../contextMenu.service";
import { Router } from "@angular/router";
import { DatabaseService } from "src/app/services/db/database.service";

@Component({
  selector: "vp-autocomplete",
  templateUrl: "./autocomplete.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];
  emailCtrl = new FormControl();
  filteredEmails: string[];
  emails: any[] = [];
  searchedUsers: any[] = [];
  isMobile: boolean = Utils.isMobileDevice();
  private isAlive$ = new Subject<boolean>();
  isEdited: any = {};
  @Input() place: string;
  @Input() formId: string;
  @Input() isFocused: false;
  @Input() otherEmails = [];
  @Input() libraryChips: boolean = false;
  @Input() showAvatar: boolean = true;
  @Input() appearance: string = "standard";
  @Input() labelInput:string = "";
  @Output() fieldIsFocused = new EventEmitter<any>();
  @Output() longPressInputEvent = new EventEmitter<any>();
  @Output() added = new EventEmitter<any>();
  @Output() removed = new EventEmitter<any>();
  @ViewChild("emailInput", {static: false}) emailInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto", {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteTrigger: MatAutocompleteTrigger;
  dragSelected: any;
  dragStatus: boolean;
  isOnMobileDevice: boolean = CommonUtils.isOnMobileDevice();
  allUserContacts: any[] = [];
  allUserGalContacts: any[] = [];
  @ViewChild("contextMenu", {static: false}) contextMenu: ContextMenuComponent;
  showToCCBCCOptions: boolean = false;
  conflictEmails = {};
  lastInput: string;
  currentInput: string;

  constructor(
    private mailService: MailService,
    private changeDetectionRef: ChangeDetectorRef,
    private convRepository: ConversationRepository,
    private dragDropService: DragDropParticipantService,
    private mailBroadcaster: MailBroadcaster,
    private store: Store<RootState>,
    private router: Router,
    private contextMenuService: ContextMenuFixService,
    private databaseService: DatabaseService,

  ) {
    this.emailCtrl.valueChanges.pipe(
      debounceTime(150),
      filter(value => value !== null),
      switchMap(value => {
        value = value.trim();
        this.currentInput = value;
        if (value === "") {
          return of(null);
        }
        return this.convRepository.getAutoCompleteList(value);
      })
      ).subscribe(res => {
        // console.log("[AutocompleteComponent-getAutoCompleteList] values ", this.currentInput, this.lastInput);

        if (this.currentInput === this.lastInput) {
          if (!!res && (res.length > 0)) {
            const newSearchedUsers = [...this.searchedUsers, ...res];
            this.searchedUsers = newSearchedUsers;
          }
        } else {
          if (res && res.length > 0) {
            this.searchedUsers = res;
          } else {
            this.searchedUsers = [];
          }
        }
        this.lastInput = this.currentInput;
        // this._filter(query);
        this.filteredEmails = _.uniqBy(this.searchedUsers, u => u.email);
        if (document.activeElement.id = "email-add-input") {
          let inputEle = <HTMLInputElement>document.activeElement;
          let autoList = <HTMLInputElement>document.querySelector(".mat-autocomplete-panel");
          if (autoList) {
            if (inputEle.offsetWidth < 180) {
              autoList.style.left = inputEle.offsetLeft - 150 +  "px";
            } else {
              autoList.style.left = inputEle.offsetLeft +  "px";
            }
          }
        }

        console.log("[AutocompleteComponent-getAutoCompleteList]", this.filteredEmails);
        this.changeDetectionRef.markForCheck();
      }, err => {
        if (Utils.isJson(err._body)) {
          this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      });
  }

  loadEmailSuggestion(query) {
    if (query === "" || /\s/g.test(query)) {
      this.filteredEmails = [];
      this.changeDetectionRef.markForCheck();
      return;
    }
    this.convRepository.getAutoCompleteList(query).subscribe(
      res => {
        // console.log("[AutocompleteComponent-getAutoCompleteList] loadEmailSuggestion ", query, res);
        this.searchedUsers = [];

        if (res && res.length > 0) {
          this.searchedUsers = res;
        } else if (Utils.validateEmail(query)) {
          this.searchedUsers.push({ title: query, name: query, email: query, image: "", checked: false });
        }

        this._filter(query);
        this.changeDetectionRef.markForCheck();
      },
      err => {
        if (Utils.isJson(err._body)) {
          this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      }
    );
  }

  ngOnChanges(changes) {
    console.log("[AutocompleteComponent][ngOnChanges]", changes);

    if (changes && changes.isFocused) {
      if (this.isFocused) {
        setTimeout(() => {
          if (this.emailInput) {
            this.emailInput.nativeElement.focus();
          }
        }, 200);
      }
    }
  }

  onFocusEvent() {
    this.fieldIsFocused.emit(this.formId);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;
    let addingVal = true;
    if ((value || "").trim()) {
      if (Utils.validateEmail(value)) {
        console.log("buildAvatar", value)
        const item = { title: value, name: value, email: value, image: this.getAvatar(value), checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
          this.added.emit(value);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      } else {
        console.error("validation failed for value: ", value);
        addingVal = false;
      }
    }
    if ((value.indexOf(",") > -1) || (value.trim().indexOf(" ") > -1) || (value.indexOf(";") > -1)) {
      let values = value.trim().split(/[,; ]/);
      values.forEach(val => {
        let sval = val.replace("<", " ").replace(">", " ").trim();
        if (Utils.validateEmail(sval)) {
          let item = { title: sval, name: sval, email: sval, image: this.getAvatar(sval), checked: false };
          if (!this.isEmailExists(sval)) {
            this.emails.push(item);
          }
          this.autocompleteTrigger.closePanel();
          this.changeDetectionRef.markForCheck();
        }
      });
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();

    this.emails.map(em=>{
      if(em.image == ""){
        em.image = this.databaseService.avatar[em['email']];
      }
    });
    console.log("[AutocompleteComponent][add]", addingVal, this.emails);
  }

  remove(email: string): void {

    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
      this.removed.emit(email);
      this.changeDetectionRef.markForCheck();
      this.mailBroadcaster.broadcast("AUTO_COMPLETE_REMOVE_ITEM");
    }

      let avatarURL = this.databaseService.avatar[email['email']];
      this.changeDetectionRef.markForCheck();
    console.log("[AutocompleteComponent][remove]", email, this.emails,  this.convRepository.contactProfiles);
  }

  getColor(emailItem) {
    let color = "#317bbc";
    if (emailItem) {
      let text = emailItem.email?.charAt(0).toUpperCase();
      color = CommonUtils.getAvatarBackground(text);
    }
    return color;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value.isGroup) {
      if (!this.isEmailExists(event.option.value.email)) {
        this.emails.push(event.option.value);
        this.added.emit(event.option.value);
      }
    } else if (event.option.value.isGroup) {
      if (event.option.value.display) {
        this.addEmailFromContactGroup(event.option.value);
        this.added.emit(event.option.value);
      } else if (!event.option.value.display) {
        this.emails.push(event.option.value);
        this.added.emit(event.option.value);
      }
    }
    this.emailInput.nativeElement.value = "";
    this.mailBroadcaster.broadcast(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG);
    this.emailCtrl.setValue(null);
    this.filteredEmails = [];
    this.changeDetectionRef.markForCheck();

    console.log("[AutocompleteComponent][selected]", this.emails);
  }

  private _filter(value: string): void {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      this.filteredEmails = this.searchedUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      this.changeDetectionRef.markForCheck();
    }
  }

  ngOnInit() {
    console.log("[AutocompleteComponent][ngOnInit]");

    this.mailBroadcaster.on<any>(MailConstants.CLOSE_AUTO_COMPLETE).pipe(takeUntil(this.isAlive$))
      .subscribe(presence => {
        if (this.autocompleteTrigger.openPanel) {
          this.autocompleteTrigger.closePanel();
          this.changeDetectionRef.markForCheck();
        }
      });

    this.dragDropService.getDraggableData().pipe(distinctUntilChanged(), takeUntil(this.isAlive$))
      .subscribe(res => {
        if (res.event === "start") {
          this.dragStatus = true;
          this.dragSelected = res.data;
          this.filteredEmails = [];
        } else {
          if  (this.dragDropService.isValid && !!this.dragDropService.target) {
            const data = res.data.item;
            const index = _.findIndex(this.emails, {email: this.dragDropService.target.item.email});
            if (res.data.id === this.formId) {
              this.emails = this.emails.filter(v => v.email !== data.email);
            }
            if (this.dragDropService.target && this.dragDropService.target.id === this.formId) {
              let newPosition = index;
              if (this.dragDropService.target.item.email) {
                if (res.data.id !== this.formId) {
                  newPosition = index + 1;
                  if (this.dragDropService.target.position === "left") {
                    newPosition = index - 1;
                  }
                }
                newPosition = newPosition > 0 ? newPosition : 0;
                this.emails.splice(newPosition, 0, data);
                this.removed.emit(data.email);
              } else {
                this.emails.push(data);
                this.added.emit(data.email);
              }
            }
          }
          this.changeDetectionRef.markForCheck();
          this.dragSelected = null;
          this.dragStatus = false;
          if (res.event === "end") {
            setTimeout(() => {
              if (this.emailInput) {
                this.emailInput.nativeElement.blur();
              }
            }, 10);
          }
        }

        console.log("[AutocompleteComponent][getDraggableData]", res, this.formId, this.dragDropService, this.emails);
      });
    this.store.select(getAllUserContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserContacts = res;
      // console.log("[getAllCuserContacts] : ", this.allUserContacts);
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getAllUserGalContacts).pipe(takeUntil(this.isAlive$)).subscribe( res => {
      this.allUserGalContacts = res;
      this.changeDetectionRef.markForCheck();
    });
  }

  getSelectedEmail(): any[] {
    return this.emails;
  }

  setEmailField(emailInfo: EmailInformation): void {
    const item = { title: emailInfo.a, name: emailInfo.p ? emailInfo.p : emailInfo.d, email: emailInfo.a, image: "", checked: false };
    console.log("[AutocompleteComponent][setEmailField] item", item);

    if (!this.isEmailExists(item.email)) {
      this.emails.push(item);
    }

    console.log("[AutocompleteComponent][setEmailField] emails", this.emails);

    this.changeDetectionRef.markForCheck();
  }

  getAvatar(email) {
    return this.convRepository.getAvatar(email);
  }

  resetEmail(): void {
    this.emails = [];
    this.changeDetectionRef.markForCheck();
    console.log("[AutocompleteComponent][resetEmail]");
  }

  onInputEvent(ev): void {
    if (typeof cordova !== "undefined") {
      if (ev.data === ";") {
        const input = ev.target;
        const value = ev.target.value.replace(/;/g, "");
        this.addEmailToChips(input, value);
      }
    }
  }

  addEmailToChips(input: any, value: string) {
    if ((value || "").trim()) {
      if (Utils.validateEmail(value)) {
        const item = { title: value, name: value, email: value, image: "", checked: false };
        if (!this.isEmailExists(value)) {
          this.emails.push(item);
        }
        this.autocompleteTrigger.closePanel();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (input) {
      input.value = "";
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  onKeyDown(ev) {
    if (typeof cordova !== "undefined") {
      if (ev.key === "Backspace" && ev.target.value === "") {
        this.emails.pop();
        setTimeout(() => {
          if (this.emailInput) {
            this.emailInput.nativeElement.focus();
          }
          this.autocompleteTrigger.closePanel();
        }, 50);
      }
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    this.closeContextMenu();
  }

  isEmailExists(value: string): boolean {
    let exist = this.otherEmails && !!this.otherEmails.find(item => item.email === value);
    exist = exist || !!this.emails.find(item => item.email === value);
    console.log("[isEmailExists]", this.otherEmails, value, exist);
    return exist;
  }

  autoCompleteClick(): void {
    if (typeof cordova !== "undefined") {
      this.mailBroadcaster.broadcast("AUTO_COMPLETE_FIELD_PRESS");
    }
  }

  dragStarted($event) {
    console.log("[dragStarted]", $event);
    this.closeContextMenu();
    this.dragDropService.dragStarted({item: $event.source.data, id: this.formId});
  }

  dragEnded($event) {
    console.log("[dragEnded]", $event);
    this.dragDropService.dragEnded({item: $event.source.data, id: this.formId});
    if ($event.source.distance.x < 0) {
      this.remove($event.source.data);
    }
    this.filteredEmails = [];
    this.changeDetectionRef.markForCheck();
  }

  mouseOver(emailItem, $event) {
    if (this.dragStatus && $event.relatedTarget) {
      console.log("[mouseOver]", this.dragStatus, this.dragSelected, emailItem, $event, $event.relatedTarget.className);
      const body = document.querySelector("body");
      let position = "right";
      const className = $event.relatedTarget.className;
      if (className === "" || className.indexOf("profile-avtar") !== -1 || className.indexOf("mail-chip-avatar") !== -1
      || className.indexOf("autocomplete-participant") !== -1) {
        position = "left";
      }
      this.dragDropService.target = {item: emailItem, id: this.formId, position: position};
      if (this.dragSelected.item.email === emailItem.email
         || this.dragSelected.id !== this.formId && this.emails.find(v => v.email === this.dragSelected.item.email)) {
        body.classList.add("drop-inactive");
        body.classList.remove("drop-active");
        this.dragDropService.isValid = false;
      } else {
        if ((!emailItem.email && this.emails.length > 0 && this.emails[this.emails.length - 1].email !== this.dragSelected.item.email)
        || emailItem.email || this.emails.length === 0) {
          body.classList.remove("drop-inactive");
          body.classList.remove("add-to-left");
          body.classList.remove("add-to-right");
          body.classList.add("drop-active");
          body.classList.add("add-to-" + position);
          this.dragDropService.isValid = true;
        }
      }
    }
  }

  mouseOut(emailItem, $event) {
    // console.log("[mouseOut]", emailItem, $event);
    this.dragDropService.isValid = false;
    this.dragDropService.target = null;
    const body = document.querySelector("body");
    body.classList.remove("drop-inactive");
    body.classList.remove("drop-active");
  }

  setValue(value) {
    if (Utils.validateEmail(value)) {
      const item = { title: value, name: value, email: value, image: "", checked: false };
      if (!this.isEmailExists(value)) {
        this.emails.push(item);
      }
      this.autocompleteTrigger.closePanel();
    }
    this.emailCtrl.setValue(null);
    this.changeDetectionRef.markForCheck();
  }

  addEmailFromContactGroup(item: any): void {
    const contact = this.allUserContacts.filter(c => !!c && c.id === item.id)[0];
    if (!!contact && contact !== null) {
      if (!!contact.m && contact.m !== null) {
        const mails = contact.m;
        mails.map( dataItem => {
          if (dataItem.type === "C") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.id === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          } else if (dataItem.type === "I") {
            const body = {
              email: dataItem.value,
              name: dataItem.value
            };
            const user = this.getUserItem(body);
            this.emails.push(user);
          } else if (dataItem.type === "G") {
            const id = dataItem.value;
            if (!!id && id !== null) {
              const contactItem = this.allUserContacts.filter(c => !!c && !!c._attrs && !!c._attrs.email && c.value && c.value === id)[0];
              if (!!contactItem && contactItem !== null) {
                const email = contactItem._attrs.email;
                const fullName = contactItem._attrs.fullName;
                const body = {
                  email: email,
                  name: !!fullName && fullName !== null ? fullName : email
                };
                const user = this.getUserItem(body);
                this.emails.push(user);
              }
            }
          }
        });
      }
    }
  }

  getUserItem(contactItem: any): any {
    const user = {
      title: "",
      name: "",
      email: "",
      image: "",
      checked: false,
      isGroup: false,
      id: ""
    };
    user.title = contactItem.name;
    user.name = contactItem.name;
    user.email = contactItem.email;
    return user;
  }

  onLongPressMobile(event, emailItem) {
    console.log("onCLongPressMobile", event, emailItem);
    if (!!this.formId) {
      this.showToCCBCCOptions = true;
      this.changeDetectionRef.markForCheck();
    }
    this.contextMenuService.show.next({
      contextMenu: this.contextMenu,
      event: event,
      item: emailItem,
    });
  }

  longPressEvent(ev: any) {
    this.longPressInputEvent.emit(ev);
  }

  closeContextMenu() {
    if (this.contextMenuService && document.querySelector(".ngx-contextmenu")) {
        const keyEvent = new KeyboardEvent("keydown", { key: "Escape" });
        this.contextMenuService.closeAllContextMenus({
            eventType: "cancel",
            event: keyEvent
        });
    }
  }

  onContextMenu($event: MouseEvent, emailItem: any) {
    console.log("[onContextMenu][emailItem]: ", emailItem, " [formId]: ", this.formId);
    if (CommonUtils.isOnMobileDevice()) {
      /* Close contentmenu in case of mobile device */
      this.closeContextMenu();
      return;
    }
    if (!!this.formId) {
      this.showToCCBCCOptions = true;
      this.changeDetectionRef.markForCheck();
    }
    this.contextMenuService.show.next({
      contextMenu: this.contextMenu,
      event: $event,
      item: emailItem,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }

  public isVisibleMoreOptions = (item: any): boolean => {
    return this.showToCCBCCOptions;
  }

  addToForm(event: any, formItem: string): void {
    console.log("[addToForm]:", event, formItem);
    if (event.item) {
      this.mailBroadcaster.broadcast(MailConstants.ADD_TO_AUTOCOMPLETE_FIELD, {
        item: event.item,
        destinationForm: formItem,
        sourceForm: this.formId
      });
    }
  }

  addToField(eventItem: any): void {
    const item = eventItem;
    console.log("[AutocompleteComponent][setEmailField] item", item);
    if (!this.isEmailExists(item.email)) {
      this.emails.push(item);
    }
    console.log("[AutocompleteComponent][setEmailField] emails", this.emails);
    this.changeDetectionRef.markForCheck();
  }

  copyItem(emailItem: any): void {
    if (!!emailItem && emailItem.item && emailItem.item.email) {
      MailUtils.copyFromTextClipboard(emailItem.item.email);
    }
  }

  setConflictEmails(data) {
    this.conflictEmails = data;
    this.changeDetectionRef.markForCheck();
  }

  isConflict(email) {
    if (typeof this.conflictEmails[email] === "undefined") {
      return false;
    } else {
      return !this.conflictEmails[email];
    }
  }

  updateEmail(event, data) {
    event.preventDefault();
    event.stopPropagation();
    const email = event.target.value;
    const oldEmail = data.email;
    if (Utils.validateEmail(email) && !this.isEmailExists(email)) {
      data.email = data.email.replace(oldEmail, email);
      data.title = email;
      data.name = email;
      const mailIndex = this.emails.findIndex(v => v.email === oldEmail);
      if (mailIndex !== -1) {
        this.emails[mailIndex] = {...this.emails[mailIndex], ...data};
      }
    }
  }

  onFocusOut(event, emailItem) {
    const email = event.target.value;
    if (!Utils.validateEmail(email)  || this.isEmailExists(email)) {
      this.isEdited[emailItem.email] = false;
    } else {
        this.updateEmail(event, emailItem);
    }
  }

  onMatChipKeyPress(event, emailItem) {
    event.stopImmediatePropagation();
    if (event.key === "Enter") {
      event.preventDefault();
      this.updateEmail(event, emailItem);
    }
    console.log("[onMatChipKeyPress]", event);
  }

  addContact(emailItem: any): void {
    if (!!emailItem && emailItem.item && emailItem.item.email) {
        this.router.navigate(["/contacts/all"]);
        setTimeout(() => {
            this.mailBroadcaster.broadcast("CONTACT_OPERATION_FROM_MAIL_DETAIL", {
                isEdit: false,
                email: emailItem.item.email
            });
        }, 1000);
    }
}

}
