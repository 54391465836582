
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, NgZone } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { MailRootState, getSelectedMailFolder, getSelectedMailTag } from "../store";
import { UpdateMailFolderSuccess, LoadMailTags, LoadMailTagsSuccess, LoadMailTagsFail, DeleteMailTagSuccess,
  DeleteMailTagFail, DeleteMailTag, UpdateMailTagSuccess, UpdateMailTagFail, UpdateMailTag, CreateMailTag,
  CreateMailTagSuccess, CreateMailTagFail,
  SetSelectedMailTag} from "../store/actions";
import { MailService } from "../shared/services/mail-service";
import { MailFolder } from "../models/mail-folder.model";
import { getMailFolders, getMailTags, getFolderById, getSharedFolderById } from "../store/selectors";
import { ToastService } from "../../common/providers/toast.service";
import {  SetSelectedMailFolder, LoadMailFolders, LoadMailFoldersSuccess, LoadMailFoldersFail } from "../store/actions/mail-folder.action";
import { ErrorType } from "../../common/enums/mail-enum";
import { ErrorService } from "../../common/providers/error-service";
import { MailTag } from "../models/mail-tag.model";
import { Observable, Subject } from "rxjs";
import { take, filter } from "rxjs/operators";
import { MailConstants } from "../../common/utils/mail-constants";
import * as _ from "lodash";
import { MailUtils } from "../utils/mail-utils";
import { ElectronService } from "src/app/services/electron.service";
import { SaveSearchFolders } from "../../actions/search-folder.action";
import { DatabaseService } from "src/app/services/db/database.service";
import { getOnlineStatus, getFederatedApps, getUserProfile, getAllDirectoryTags, RootState } from "src/app/reducers";
import { ConversationRepository } from "./conversation.repository";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { SearchResponse } from "src/app/mail/shared/models/search-item";
import { ContactInfo } from "src/app/mail/shared/models/contact-info.model";
import { UserProfile } from "src/app/shared/models/user-profile";
import { ConfigService } from "src/app/config.service";
import { DirectoryTag } from "../models/directory-tag.model";
import { CreateDirectoryTag, CreateDirectoryTagFail, CreateDirectoryTagSuccess } from "src/app/actions/directory-tag.action";
import { AppService } from "src/app/services/app.service";
import { CreateFolderComponent } from "../create-folder/create-folder.component";


@Injectable()
export class CommonRepository {

  mailFolders: MailFolder[] = [];
  selectedMailFolder: MailFolder = null;
  subscribed: boolean;
  isOnline = false;
  hideListView: any = false;
  tagColorRefresh$ = new Subject<boolean>();
  constructor(private mailService: MailService,
    private store: Store<MailRootState | RootState>,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private zone: NgZone,
    private convRepository: ConversationRepository,
    private electronService: ElectronService,
    private databaseService: DatabaseService,
    private configService: ConfigService,
    private appService: AppService
    ) {

      console.log("[CommonRepository] constructor");

      this.store.select(getOnlineStatus).subscribe(res => {
        this.isOnline = res;
      });
  }

  // // decorator
  // RequireInternet(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
  //     console.log("[CommonRepository] RequireInternet");
  //
  //     const originalMethod = descriptor.value;
  // }

  showNoInternetToastIfRequired() {
    if (!this.isOnline) {
      this.toastService.show("NO_INTERNET_CONNECTION");
      return true;
    }
    return false;
  }

  initData() {
    if (!this.subscribed) {
      console.log("[CommonRepository] initData");

      this.subscribed = true;

      this.store.select(getMailFolders).subscribe(res => {
        if (!!res && res[0] && res[0].id === "2") {
         this.mailFolders = res;

         // store folder to local storage
         // TODO: do we really need it ?
         const folders = _.cloneDeep(res);
         setTimeout(() => {
           folders.map(folder => {
             delete folder["folder"];
             delete folder.children;
           });
           if (this.electronService.isElectron) {
             this.electronService.setToStorage("mailFolders", folders);
           } else {
             localStorage.setItem("mailFolders", JSON.stringify(folders));
           }
         }, 500);

        }
      });

      this.store.select(getSelectedMailFolder).subscribe(res => {
        this.selectedMailFolder = res;
      });
    }
  }

  getMailFolders() {
    console.log("[CommonRepository][getMailFolders]");

    this.store.dispatch(new LoadMailFolders());

    const body = {view: "message, conversation"};

    if (this.isOnline) {
      this.mailService.getMailFolders(body, false).subscribe(res => {
          const folders = MailUtils.getMailFolders(res);

          console.log("[CommonRepository][getMailFolders] res", folders);

          this.databaseService.addFolders(folders);

          this.store.dispatch(new LoadMailFoldersSuccess({ folders }));
        }, err => {
          this.store.dispatch(new LoadMailFoldersFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    } else {
      this.databaseService.getFolders().subscribe(folders => {
        console.log("[CommonRepository][getMailFolders] res (offline)", folders);
        this.store.dispatch(new LoadMailFoldersSuccess({ folders }));
      });
    }
  }

  getSearchFolders(type: string) {
    const body = {view: "message, conversation"};
    this.mailService.getSearchFolders(body, false).subscribe(
      res => {
        res = MailUtils.getSearchFolders(res, type);
        this.store.dispatch(
          new SaveSearchFolders({ folders: res })
        );
      },
      err => {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  reloadMailFolders(folderId: string, total, totalUnread = 0) {
    this.zone.runOutsideAngular(() => {
      if (folderId.indexOf(":") === -1) {
        this.store.pipe(select(state => getFolderById(state, folderId)), take(1)).subscribe(f => {
          if (!!f) {
            f.n = (+f.n + total).toString();
            f.u = (+f.u + totalUnread).toString();
            this.store.dispatch(new UpdateMailFolderSuccess({ id: folderId, changes: {n: f.n, u: f.u} }));
          }
        });
      } else {
        this.store.pipe(select(state => getSharedFolderById(state, folderId)), take(1)).subscribe(f => {
          if (!!f) {
            f.n = (+f.n + total).toString();
            f.u = (+f.u + totalUnread).toString();
            this.store.dispatch(new UpdateMailFolderSuccess({ id: folderId, changes: {n: f.n, u: f.u} }));
          }
        });
      }
    });
  }

  getTags(): void {
    console.log("[CommonRepository][getTags]");

    this.store.dispatch(new LoadMailTags());

    if (this.isOnline) {
      this.mailService.getTags().subscribe(tags => {
        console.log("[CommonRepository][getTags] res", tags);

        this.databaseService.addTags(tags).subscribe();

        this.store.dispatch(new LoadMailTagsSuccess({ tags }));
      }, err => {
        this.store.dispatch(new LoadMailTagsFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      });
    } else {
      this.databaseService.getTags().subscribe(tags => {
        console.log("[CommonRepository][getTags] res (offline)", tags);
        this.store.dispatch(new LoadMailTagsSuccess({ tags }));
      });
    }
  }

  deleteTag(tagId: string): Observable<string> {
    const subject = new Subject<string>();
    this.store.dispatch(new DeleteMailTag());
    this.mailService.tagAction({id: tagId, op: "delete"}).subscribe(
      res => {
        this.toastService.show(MailConstants.TAG_REMOVE_LBL);
      this.store.dispatch(new DeleteMailTagSuccess(tagId));
      subject.next(tagId);
    }, err => {
      this.store.dispatch(new DeleteMailTagFail());
      subject.next(null);
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
    return subject.asObservable();
  }

  createTag(tagName: string, rgb: string): Observable<MailTag> {
    const subject = new Subject<any>();
    this.store.dispatch(new CreateMailTag());
    this.mailService.createTag({tagName, rgb}).subscribe(
      res => {
        console.log("[CreateMailTagSuccess]", res);
        const tag = res.tag[0] as MailTag;
        subject.next(tag);
      this.store.dispatch(new CreateMailTagSuccess(tag));
    }, err => {
      subject.next(null);
      this.store.dispatch(new CreateMailTagFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
    return subject.asObservable().pipe(take(1));
  }

  updateTagColor(tagId: string, rgb: string, moduleType?): void {
    this.store.dispatch(new UpdateMailTag());
    this.mailService.tagAction({id: tagId, op: "color", rgb: rgb}).subscribe(
      res => {
      this.store.dispatch(new UpdateMailTagSuccess({id: tagId, changes: {rgb: rgb}}));
      if (moduleType === "mail") {
        let tag;
        this.store.select(getSelectedMailTag).pipe(take(1)).subscribe(res => {
          if (res) {
            tag = res;
          };
        });
        if (tag && tag?.id === tagId) {
          tag.rgb = rgb;
          this.store.dispatch(new SetSelectedMailTag(tag));
          this.tagColorRefresh$.next(true);
        };
      };
    }, err => {
      this.store.dispatch(new UpdateMailTagFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  renameTag(tagId: string, name: string): void {
    this.store.dispatch(new UpdateMailTag());
    this.mailService.tagAction({id: tagId, op: "rename", name: name}).subscribe(
      res => {
        this.toastService.show(MailConstants.TAG_NAME_RENAME_LBL);
      this.store.dispatch(new UpdateMailTagSuccess({id: tagId, changes: {name: name}}));
    }, err => {
      this.store.dispatch(new UpdateMailTagFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  getTagsList(): Observable<any[]> {
    console.log("[getTagsList()][useVNCdirectoryAuth]:", this.configService.useVNCdirectoryAuth, this.appService.setShowGlobalTags);
    if (this.configService.useVNCdirectoryAuth) {
      console.log("[getAllDirectoryTags]:");
      return this.store.select(getAllDirectoryTags);
    }
    console.log("[getMailTags]:");
    return this.store.select(getMailTags);
  }

  getTagsListWithParam(global: boolean): Observable<any[]> {
    console.log("[getTagsList()][useVNCdirectoryAuth]:", this.configService.useVNCdirectoryAuth, this.appService.setShowGlobalTags);
    if (this.configService.useVNCdirectoryAuth && global) {
      console.log("[getAllDirectoryTags]:");
      return this.store.select(getAllDirectoryTags);
    }
    console.log("[getMailTags]:");
    return this.store.select(getMailTags);
  }


  setRoutedFolder(folderId: string, rootPath: string) {
    let rootFolder: any = null;
    if (folderId.includes(":")) {
      rootFolder = this.getRootSharedFolder(folderId, rootPath);
    } else {
      rootFolder = this.mailFolders.find(fd => fd.absFolderPath === "/" + rootPath);
    }
    if (rootFolder && rootFolder.id === folderId) {
      this.store.dispatch(new SetSelectedMailFolder(rootFolder));
    } else if (rootFolder) {
      const folder = this.getSelectedSubFolder(folderId, rootFolder);
      if (folder) {
        this.store.dispatch(new SetSelectedMailFolder(folder));
      }
    }
  }

  getSelectedSubFolder(folderId: string, rootFolder: MailFolder): MailFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.children) {
      for (let i = 0; i < rootFolder.children.length; i++) {
        if (folderId === rootFolder.children[i].id) {
          return rootFolder.children[i];
        } else if (rootFolder.children[i].children) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.children[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  getRootFolder(targetFolder: MailFolder): MailFolder {
    if (!targetFolder) {
      return null;
    }
    if (targetFolder.id && targetFolder.id.includes(":")) {
      const folderNames: any[] = targetFolder.absFolderPath.split("/");
      const regShareFolderIdExp = /[^:\\]+/;
      const folderZid = targetFolder.id.match(regShareFolderIdExp)[0];

      const rootFolder = this.mailFolders.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      return rootFolder;
    } else if (targetFolder.absFolderPath) {
      const reg = /[^/\\]+/;
      const rootFolderName = targetFolder.absFolderPath.match(reg)[0];
      return this.mailFolders.find(folder => folder.absFolderPath.match(reg)[0].toLowerCase() === rootFolderName.toLowerCase());
    }
  }

  getAbsolutePathShareFolder (targetFolder: MailFolder) {
    // Check folder is type shared (id format <parent-zid>:<subfolder-id from source account>)
    if (!targetFolder.id.includes(":")) {
      return targetFolder.absFolderPath;
    } else {
      // const rootFolder = this.getRootSharedFolder(targetFolder.id);
      const folderNames: any[] = targetFolder.absFolderPath.split("/");

      const reg = /[^:\\]+/;
      const folderZid = targetFolder.id.match(reg)[0];

      const rootFolder = this.mailFolders.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      const subPath = targetFolder.absFolderPath.substr(0, targetFolder.absFolderPath.indexOf(rootFolder.oname) +
      (rootFolder.oname.length));
      return targetFolder.absFolderPath.replace(subPath, "/" + rootFolder.name);
    }
  }

  refreshCurrentRootMailFolder() {
    this.getMailUpdatedRootFolder(this.selectedMailFolder);
  }

  refreshSelectedSubFolder() {
    if (this.selectedMailFolder && this.selectedMailFolder.uuid) {
      const rootFolder = this.selectedMailFolder.absFolderPath === "/Junk" ?
      this.selectedMailFolder : this.getRootFolder(this.selectedMailFolder);
      this.getMailUpdatedRootFolder(rootFolder);
    }
  }

  refreshTrashFolder() {
      const rootFolder = this.mailFolders.find(f => f.id === MailConstants.TRASH_MAIL_FOLDER_ID);
      if (rootFolder) {
        this.getMailUpdatedRootFolder(rootFolder);
      }
  }

  refreshSpamFolder() {
    const rootFolder = this.mailFolders.find(f => f.id === MailConstants.SPAM_MAIL_FOLDER_ID);
    if (rootFolder) {
      this.getMailUpdatedRootFolder(rootFolder);
    }
  }

  getParentFolder(targetFolder: MailFolder, rootFolder: MailFolder) {
    return this.getSelectedSubFolder(targetFolder.l, rootFolder);
  }

  getMailUpdatedRootFolder(folder: MailFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "message",
      folder: {
        uuid: folder.uuid
      }
    };
    this.mailService.getMailFolders(body, true).subscribe(res => {
      if (res && res.length === 1) {
        const tempFolder = res[0] as MailFolder;
        if (!tempFolder.u) {
          tempFolder.u = "0";
        }
        if (folder.name.toLocaleLowerCase() === "spam") {
          tempFolder.name = "Spam";
        }
        if (tempFolder.id === "2" && this.electronService.isElectron) {
          // update badge count
          console.log("[update badge count]");
          this.electronService.setBadge(+tempFolder.u);
        }
        this.store.dispatch(new UpdateMailFolderSuccess({ id: tempFolder.id, changes: tempFolder }));
      } else {
        this.store.dispatch(new UpdateMailFolderSuccess({ id: folder.id, changes: folder }));
      }
      if (keyMessage) {
        this.toastService.show(keyMessage);
      }
    });
  }

  getRootSharedFolder(folderId, rootFolderName: string): MailFolder {
      const reg = /[^:\\]+/;
      const folderZid = folderId.match(reg)[0];
      return this.mailFolders.find(folder => (folder.zid && folder.zid === folderZid && rootFolderName === folder.name));
  }

  sendEmail(email): void {
    const emails: any[] = [];
    emails.push(email);
    let mailAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        mailAppURL = apps.filter( ap => ap.name.toLowerCase() === "vncmail")[0]?.options.url;
    });
    mailAppURL = this.getURL(mailAppURL);
    const sendURL = mailAppURL + "mail/compose?to=" + emails.toString();
    if (environment.isCordova) {
        if (device.platform === "iOS") {
            window.open(sendURL, "_system");
        } else if (device.platform === "Android") {
            navigator.app.loadUrl(sendURL, {
                openExternal: true
            });
        }
      } else {
        this.router.navigate(["/mail/compose"], { queryParams: { to: emails.toString() } });
      }
  }

  getURL(url: string): string {
      let sendURL: string = "";
      if (url.endsWith("/")) {
          sendURL = url;
      } else {
          sendURL = url + "/";
      }
      return sendURL;
  }

  makeTalkAudioChatVideoOperation(email, action: string, groupAction: string): void {
      const emails: any[] = [];
      emails.push(email);
      this.talkCallAudioChatVideoOperation(action, emails.toString());
  }

  talkCallAudioChatVideoOperation(action: string, target: string, groupName?: string) {
    let talkAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctalk")[0]?.options?.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    if (this.electronService.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://main");
        console.log("appswitcher nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          talkAppURL = "vnctalk://main/";
        }
      } catch (e) {
        console.error("[common-repository] error: ", e);
      }
    }
    let sendURL;
    if (talkAppURL.endsWith("/")) {
      sendURL = talkAppURL + "talk/trigger?action=" + action + "&target=" + target;
    } else {
      sendURL = talkAppURL + "/talk/trigger?action=" + action + "&target=" + target;
    }
    if (groupName) {
      sendURL += "&groupName=" + groupName;
    }
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  createTask(email): void {
    let taskAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      taskAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctask")[0]?.options?.url;
    });
    taskAppURL = this.getURL(taskAppURL);
    const sendURL = taskAppURL + "task/open?action=compose&assignee=" + email;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  searchDocs(params): Observable<SearchResponse> {
    return this.mailService.searchDocs(params);
  }

  jumpToChat(contact: ContactInfo, talkId: string, createdDt: string): void {
    let talkAppURL: string = "";
    let timestamp = new Date(createdDt).getTime();
    let target = "";
    if ( contact && contact.jid) {
      target = contact.jid;
    } else {
      if ( contact && contact.emails && contact.emails.length > 0) {
        target = contact.emails[0].email;
      } else {
        this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
        return;
      }
    }
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctalk")[0]?.options?.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    try {
      if (environment.isElectron) {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://main");
        console.log("appswitcher nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          talkAppURL = "vnctalk://main/";
        }
      }
    } catch (e) {
      console.error("[common-repository] error: ", e);
    }
    let sendURL;
    if (talkAppURL.endsWith("/")) {
      sendURL = talkAppURL + "talk/trigger?action=jump-to-chat&target=" + target + "&chat_id=" + talkId + "&timestamp=" + timestamp;
    } else {
      sendURL = talkAppURL + "/talk/trigger?action=jump-to-chat&target=" + target + "&chat_id=" + talkId + "&timestamp=" + timestamp;
    }
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  restrictOpenMail(item: any): boolean {
    let isOpenMail: boolean = true;
    let userEmail;
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(res => {
      if (res.email) {
          userEmail = MailUtils.checkEmailArray(res.email);
      } else {
          const profileUser = MailUtils.getProfileFromStorage();
          userEmail = MailUtils.checkEmailArray(profileUser.email);
      }
    });
    if (!!userEmail && userEmail !== null) {
      if (userEmail && userEmail === item.from) {
        isOpenMail = false;
      }
    }
    if (!isOpenMail) {
      this.toastService.show("EMAIL_OPEN_RESTRICT_MESSAGE");
    }
    return isOpenMail;
  }

  openMail(folderId: any, mailId: any): void {
    this.store.pipe(select(state => getFolderById(state, folderId)), take(1)).subscribe(f => {
      console.log("openMail folder f:", f);
      let newLocationPath = "/mail" + f.absFolderPath.toLowerCase() + "/detail/m/" + mailId;
      this.router.navigate([newLocationPath]);
    });
  }

  openTask(taskId: string): void {
    let taskAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      taskAppURL = apps.filter( ap => ap.name.toLowerCase() === "vnctask")[0]?.options?.url;
    });
    taskAppURL = this.getURL(taskAppURL);
    const sendURL = taskAppURL + "redirect?action=open-task&id=" + taskId;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  createTicket(email): void {
    let projectAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      projectAppURL = apps.filter( ap => ap.name.toLowerCase() === "vncproject")[0]?.options?.url;
    });
    projectAppURL = this.getURL(projectAppURL);
    const sendURL = projectAppURL + "issues/new?assignee_username=" + email;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  getZimbraTagsList(): Observable<any[]> {
    console.log("[getMailTags]:");
    return this.store.select(getMailTags);
  }

  createDirectoryTag(tagName: string, rgb: string): Observable<DirectoryTag> {
    const subject = new Subject<any>();
    this.store.dispatch(new CreateDirectoryTag());
    this.appService.createDirectoryTag(tagName, rgb).subscribe(
      res => {
        console.log("[createDirectoryTag][response]:", res);
        if (res.tag) {
          const dt: DirectoryTag = {} as DirectoryTag;
          const t = res.tag;
          dt.id = t.id;
          dt.name = t.name;
          if (t.color_hex && t.color_hex !== null) {
            dt.color = t.color_hex;
          } else {
            dt.color = MailConstants.DEFAULT_COLOR;
          }
          subject.next(dt);
          this.store.dispatch(new CreateDirectoryTagSuccess(dt));
          if (!this.isTagAvailableInZimbra(tagName)) {
            this.createTag(tagName, rgb);
          }
        } else {
          subject.next(true);
          this.store.dispatch(new CreateDirectoryTagFail());
        }
    }, err => {
      // subject.next(null);
      this.store.dispatch(new CreateDirectoryTagFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
    return subject.asObservable().pipe(take(1));
  }

  isTagAvailableInZimbra(tagName: string): boolean {
    let isTagAvailable: boolean = false;
    let zimbraTags: MailTag[] = [];
    this.getZimbraTagsList().pipe(take(1)).subscribe(res => {
      zimbraTags = res;
    });
    if (zimbraTags.length > 0) {
      const available = zimbraTags.filter(t => t.name.toLowerCase().trim() === tagName.toLowerCase().trim())[0];
      if (!!available) {
        isTagAvailable = true;
      }
    }
    return isTagAvailable;
  }

  getOICAPIToken() {
    this.appService.getOICAPIToken();
  }

  public renameFolder(folder: MailFolder,matDialog, component?) {
    if (this.showNoInternetToastIfRequired()) {
      return;
    }

    matDialog.open(component? component: CreateFolderComponent, {
      width: "480px",
      height: "200px",
      autoFocus: true,
      panelClass: "mail_folder-create_dialog",
      data: { targetFolder: folder, isRename: true }
    });
  }
}
