
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc_mail_autocomplete">
  <mat-form-field class="vnc_mail-chip-list" [appearance]="appearance" floatLabel="always">
    <mat-label *ngIf="labelInput !==''">{{labelInput}}</mat-label>
    <mat-chip-list #chipList>
      <ng-container  *ngIf="!libraryChips">
        <mat-chip cdkDropList *ngFor="let emailItem of emails" [selectable]="selectable" [removable]="removable" (removed)="remove(emailItem)" [vpContactDetailTooltip]="emailItem.email" [tooltipItem]="emailItem.email" [contextMenu]="contextMenu" (contextmenu)="onContextMenu($event, emailItem)">
          <div (dblclick)="isEdited[emailItem.email] = true" [class.is-conflict]="isConflict([emailItem.email])" class="autocomplete-participant" (mouseover)="mouseOver(emailItem, $event)" (mouseout)="mouseOut(emailItem, $event)"
          vpLongPress [timeout]="700" (onLongPress)="$event.preventDefault(); $event.stopPropagation(); onLongPressMobile($event, emailItem);"
          (cdkDragStarted)="dragStarted($event)" [cdkDragData]="emailItem" (cdkDragEnded)="dragEnded($event)" [cdkDragDisabled]="isOnMobileDevice || isEdited[emailItem.email]" cdkDrag>
              <vp-avatar [type]="'mail-chip-avatar'" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}"> </vp-avatar>
              <ng-container *ngIf="!isEdited[emailItem.email]">{{emailItem.name}}</ng-container>
              <ng-container *ngIf="isEdited[emailItem.email]"><input (keydown)="onMatChipKeyPress($event, emailItem)" (focusout)="onFocusOut($event, emailItem)" class="email-input" type="email" [value]="emailItem.email"></ng-container>

              <mat-icon matChipRemove *ngIf="removable && !isEdited[emailItem.email]" class="disable-select">close</mat-icon>
              <div class="autocomplete-participant-preview" *cdkDragPreview>
                <div class="autocomplete-participant">
                  <vp-avatar [type]="'mail-chip-avatar'" [user]="getAvatar(emailItem.email)"> </vp-avatar>{{emailItem.name}}
                </div>
              </div>
          </div>
        </mat-chip>
      </ng-container>

      <ng-container  *ngIf="libraryChips">
        <div *ngFor="let emailItem of emails">
          <vnc-chips [text]="emailItem.name" [removable]="true" [closeTooltipLabel]="'COMMON.REMOVE' | translate"
          [fallBackInfo]="{text: emailItem.name[0], bgColor: getColor(emailItem), borderColor: getColor(emailItem)}"
          (removeIconClick)="remove(emailItem)" [imgSrc]="emailItem.image || emailItem.avatar" type="dynamic" size="s"></vnc-chips>
        </div>
      </ng-container>

      <input autocomplete="nope" matInput [placeholder]="emails.length === 0 ? place :''" (mouseover)="mouseOver({}, $event)" (mouseout)="mouseOut({}, $event)" #emailInput [formControl]="emailCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (focus)="onFocusEvent()"
        (matChipInputTokenEnd)="add($event)" (input)="onInputEvent($event)" (keydown)="onKeyDown($event)" (click)="autoCompleteClick()" id="email-add-input" vpLongPress [timeout]="700" (onLongPress)="longPressEvent('');">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
      <mat-option *ngFor="let emailItem of filteredEmails" [value]="emailItem">
        <span class="suggestion">
          <vp-avatar [type]="'mail-detail-avatar'" *ngIf="showAvatar" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}"> </vp-avatar>
          <div class="email_name_division">
            <h3 class="disable-select">{{emailItem.name}}</h3>
            <p class="disable-select">{{emailItem.email}}</p>
          </div>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <context-menu #contextMenu id="contextMenuAutoComplete" [menuClass]="'autocomplete-menu'">
    <ng-template contextMenuItem (execute)="remove($event.item)">
      <div class="context-item">
        <mat-icon>delete</mat-icon>
        <span>{{'COMMON.DELETE' | translate }}</span>
      </div>
    </ng-template>
    <ng-template contextMenuItem (execute)="copyItem($event)">
      <div class="context-item">
        <mat-icon>content_copy</mat-icon>
        <span>{{ 'COPY' | translate }}</span>
      </div>
    </ng-template>
    <ng-template contextMenuItem (execute)="addContact($event)">
      <div class="context-item">
        <mat-icon
          class="mdi-16px"
          fontSet="mdi"
          fontIcon="mdi-account-plus"></mat-icon>
        <span>{{ 'ADD_TO_CONTACTS' | translate }}</span>
      </div>
    </ng-template>
    <ng-template contextMenuItem divider="true" [visible]="isVisibleMoreOptions"></ng-template>
    <ng-template contextMenuItem [visible]="isVisibleMoreOptions" [enabled]="formId === 'ccForm' || formId === 'bccForm'" (execute)="addToForm($event, 'toForm')">
      <div class="context-item">
        <mat-icon></mat-icon>
        <span>{{ 'MOVE_TO_TO' | translate }}</span>
      </div>
    </ng-template>
    <ng-template contextMenuItem [visible]="isVisibleMoreOptions" [enabled]="formId === 'bccForm' ||  formId === 'toForm'" (execute)="addToForm($event, 'ccForm')">
      <div class="context-item">
        <mat-icon></mat-icon>
        <span>{{ 'MOVE_TO_CC' | translate }}</span>
      </div>
    </ng-template>
    <ng-template contextMenuItem [visible]="isVisibleMoreOptions" [enabled]="formId === 'ccForm' || formId === 'toForm'" (execute)="addToForm($event, 'bccForm')">
      <div class="context-item">
        <mat-icon></mat-icon>
        <span>{{ 'MOVE_TO_BCC' | translate }}</span>
      </div>
    </ng-template>
  </context-menu>
</div>
