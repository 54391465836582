
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkTreeModule } from "@angular/cdk/tree";
import { ImageCropperModule } from "ngx-img-cropper";
import { PlatformModule } from "@angular/cdk/platform";
import { UserAvatarComponent } from "./components/user-avtar/user-avatar.component";
import { FormatFileSizePipe } from "./pipes/format-file-size.pipe";
import { ScrollListDirective, VNCLongPressDirective } from "./directives";
import { MailOperationButtonComponent } from "./components/mail-operation-button/mail-operation-button.component";
import { DebounceClickDirective } from "./directives/debound-click.directive";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { AvatarDialogComponent } from "./components/avatar-dialog/avatar-dialog.component";
import { AvatarCropperDialogComponent } from "./components/avatar-cropper-dialog/avatar-cropper-dialog.component";
import { AppsDialogComponent } from "./components/apps-dialog/apps-dialog.component";
import { AppSwitcherComponent } from "./components/app-switcher";
import { PreferencesComponent } from "./components/preferences-dialog/preferences.component";
import { ContextMenuModule, ContextMenuService } from "ngx-contextmenu";
import { QuillModule } from "ngx-quill";
import { FlexLayoutModule } from "@angular/flex-layout";
import { VirtualScrollingComponent } from "./components/virtual-scrolling/virtual-scrolling.component";
import { CustomSpinnerComponent } from "./components/custom-spinner/custom-spinner.component";
import { TagNameSearchPipe } from "./pipes/mail-tag-search.pipe";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";
import { FolderNameSearchPipe } from "./pipes/mail-folder-search.pipe";
import { SortMessagesPipe } from "./pipes/sortMessages.pipe.";
import { DisplayTimePipe } from "./pipes/displayTime.pipe";
import { TagsListComponent } from "../mail/shared/components/tags-list/tags-list.component";
import { ColorChromeModule } from "ngx-color/chrome";
import { AdvancedContactSearchComponent } from "../contacts/components/advanced-search/advanced-contact-search.component";
import { MdlModule } from "@angular-mdl/core";
import { MdlSelectModule } from "@angular-mdl/select";
import { SidebarService } from "../contacts/sidebar/sidebar.service";
import { AdvancedSearchDialogComponent } from "./components/advanced-search-dialog/advanced-search-dialog.component";
import { DragDropDirective } from "./directives/drag-drop-upload.directive";
import { FilePreviewOverlayComponent } from "./components/file-preview-overlay/file-preview-overlay.component";
import {
  FilePreviewOverlayToolbarComponent
} from "./components/file-preview-overlay/file-preview-overlay-toolbar/file-preview-overlay-toolbar.component";
import { FilePreviewOverlayService } from "./components/file-preview-overlay/file-preview-overlay.service";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FolderListComponent } from "../mail/shared/components/folder-list/folder-list.component";
import { AppointmentReminderDialogComponent } from "./components/appointment-reminder/appointment-reminder.component";
import { AutoFocusDirective } from "./pipes/auto-focus.directive";
import { MoveCalendarDialogComponent } from "./components/move-calendar-dialog/move-calendar-dialog.component";
import { ModifyRecurAppointmentDialogComponent } from "./components/modify-recur-appointment-dialog/modify-recur-appointment-dialog.component";
import {
  ModifyAppointmentConfirmDialogComponent
} from "./components/modify-appointment-confirmation-dialog/modify-confirm-appointment.component";
import { DateRangeSelectDialogComponent } from "./components/date-range-select-dialog/date-range-select-dialog.component";
import { RecoverCalendarDeleteComponent } from "./components/recover-calendar-delete/recover-calendar-delete.component";
import {
  ConfirmSendInviteDialogComponent
} from "./components/confirm-delete-send-invite-dialog/confirm-delete-send-invite-dialog.component";
import { RemoveAllExceptionDialogComponent } from "./components/remove-all-exception-instance-dialog/remove-all-exception-dialog.component";
import { DeleteSeriesConfirmDialogComponent } from "./components/delete-series-confirm-dialog/delete-series-confirm-dialog.component";
import { CalendarPrintDialogComponent } from "./components/calendar-print-dialog/calendar-print-dialog.component";
import { CalendarEquipmentDialogComponent } from "./components/calendar-equipment-dialog/calendar-equipment-dialog.component";
import {
  CalendarEquipmentAutoCompleteComponent
} from "./components/calendar-equipment-auto-complete/calendar-equipment-autocomplete.component";
import { ConflictEquipmentDialogComponent } from "./components/conflict-equipment-dialog/conflict-equipment-dialog.component";
import { SaveChangeAppointmentDialogComponent } from "./components/save-change-appointment/save-change-appointment.component";
import { CalendarFindLocationDialogComponent } from "./components/calendar-find-location-dialog/calendar-find-location-dialog.component";
import {
  CalendarMobileSelectAddressDialogComponent
} from "./components/calendar-mobile-select-address-dialog/mobile-select-address-dialog.component";
import { CalendarSelectAddressesDialogComponent } from "./components/calendar-select-addresses-dialog/select-addresses-dialog.component";
import { UiScrollModule } from "ngx-ui-scroll";
import { CustomRepeatDialogComponent } from "./components/custom-repeat-dialog/custom-repeat-dialog.component";
import { DeleteAppointmentNotifyDialogComponent } from "./components/delete-appointment-notify-user/delete-appointment-notify-user.component";
import { AddExternalCalendarDialogComponent } from "./components/add-external-calendar/add-external-calendar.component";
import { MobileCalendarContextMenuDialogComponent } from "./components/mobile-calendar-context-menu/mobile-calendar-contextmenu.component";
import { TFASettingsComponent } from "./components/tfa-settings/tfa-settings.component";
import { GeneralConfirmationDialogComponent } from "./components/general-confirmation-dialog/general-confirmation-dialog.component";
import { FindShareDialogComponent } from "./components/find-share-dialog/find-share-dialog.component";
import { TooltipDirective } from "./directives/tooltip.directive";
import { SendLaterDialogComponent } from "./components/send-later-dialog/send-later-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MatDividerModule } from "@angular/material/divider";
import { MatNativeDateModule } from "@angular/material/core";
import { MailDetailEmailOperationDialogComponent } from "./components/mail-detail-email-operation/mail-detail-email-operation.component";
import { AppearanceDialogComponent } from "./components/appearance-dialog/appearance-dialog.component";
import { SearchFolderListComponent } from "src/app/mail/shared/components/search-folder-list/search-folder-list.component";
import { ResizableModule } from "angular-resizable-element";
import { AttachContactDialogComponent } from "../mail/shared/components/attach-contact-dialog/attach-contact-dialog.component";
import { ContactDetailTooltipDirective, ContactTooltipWindowComponent } from "./directives/contact-detail.directive";
import { AddItemDialogComponent } from "./components/add-item-dialog/add-item-dialog.component";
import { CreateCalendarItemFolderComponent } from "./components/create-calendar-folder/create-calendar-folder.component";
import { AppointmentInviteReplyDialogComponent } from "./components/event-invite-operation-dialog/event-invite-operation-dialog.component";
import { EmailNotificationConfigureComponent } from "./components/email-notification-configure/email-notification-configure.component";
import { SealAndSendSnackbarComponent } from "../mail/shared/components/seal-send-snackbar/seal-send-snackbar.component";
import { NgxOnlyOfficeModule } from "ngx-onlyoffice";
import { ContextMenuFixService } from "./contextMenu.service";
import { CanDeactivateGuard } from "./can-deactivate/can-deactivate.guard";
import { VNCHeaderModule, VncLibraryModule, VNCVerticalTabsModule } from "vnc-library";
import { CommonDialogComponent } from "./components/common-dialog/common-dialog.component";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { SwipeDirective } from "./directives/swipe.directive";
import { CreateContactDialogComponent } from "./components/create-contact-dialog/create-contact-dialog.component";
import { LogoutConfirmationComponent } from "./components/logout-confirmation/logout-confirmation.component";
import { VNCStripProgress } from "./components/strip-progress/strip-progress.component";

const ANGULAR_MATERIALS = [
  CdkTreeModule,
  DragDropModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule,
  ScrollingModule,
  QuillModule,
  FlexLayoutModule,
  ContextMenuModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatSortModule,
  MatBottomSheetModule,
];

const DIRECTIVES = [
  ScrollListDirective,
  VNCLongPressDirective,
  DebounceClickDirective,
  DragDropDirective,
  TooltipDirective,
  ContactDetailTooltipDirective,
  SwipeDirective,
  CommonDialogComponent
];

const DIALOGUES = [
  ConfirmationDialogComponent,
  LogoutConfirmationComponent,
  AvatarDialogComponent,
  AvatarCropperDialogComponent,
  AppsDialogComponent,
  PreferencesComponent,
  AdvancedSearchDialogComponent,
  FilePreviewOverlayComponent,
  AppointmentReminderDialogComponent,
  MoveCalendarDialogComponent,
  ModifyRecurAppointmentDialogComponent,
  ModifyAppointmentConfirmDialogComponent,
  DateRangeSelectDialogComponent,
  RecoverCalendarDeleteComponent,
  ConfirmSendInviteDialogComponent,
  RemoveAllExceptionDialogComponent,
  DeleteSeriesConfirmDialogComponent,
  CalendarPrintDialogComponent,
  CalendarEquipmentDialogComponent,
  CalendarEquipmentAutoCompleteComponent,
  ConflictEquipmentDialogComponent,
  SaveChangeAppointmentDialogComponent,
  CalendarFindLocationDialogComponent,
  CalendarMobileSelectAddressDialogComponent,
  CalendarSelectAddressesDialogComponent,
  CustomRepeatDialogComponent,
  DeleteAppointmentNotifyDialogComponent,
  AddExternalCalendarDialogComponent,
  MobileCalendarContextMenuDialogComponent,
  GeneralConfirmationDialogComponent,
  FindShareDialogComponent,
  SendLaterDialogComponent,
  MailDetailEmailOperationDialogComponent,
  AppearanceDialogComponent,
  AttachContactDialogComponent,
  ContactTooltipWindowComponent,
  AddItemDialogComponent,
  CreateCalendarItemFolderComponent,
  AppointmentInviteReplyDialogComponent,
  EmailNotificationConfigureComponent,
  SealAndSendSnackbarComponent,
  CreateContactDialogComponent
];

const PIPES = [
];

@NgModule({
    imports: [
        CommonModule,
        PlatformModule,
        FormsModule,
        MdlModule,
        MdlSelectModule,
        TranslateModule,
        ReactiveFormsModule,
        ImageCropperModule,
        UiScrollModule,
        ColorChromeModule,
        ResizableModule,
        ...ANGULAR_MATERIALS,
        VNCHeaderModule,
        VNCVerticalTabsModule,
        NgxOnlyOfficeModule,
        VncLibraryModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ColorChromeModule,
        UiScrollModule,
        NgxOnlyOfficeModule,
        ...ANGULAR_MATERIALS,
        // Directives
        ...DIRECTIVES,
        // Pipes
        ...PIPES,
        UserAvatarComponent,
        VNCStripProgress,
        FormatFileSizePipe,
        TagNameSearchPipe,
        FolderNameSearchPipe,
        MailOperationButtonComponent,
        SafeHtmlPipe,
        SortMessagesPipe,
        AppSwitcherComponent,
        VirtualScrollingComponent,
        CustomSpinnerComponent,
        AutocompleteComponent,
        TagsListComponent,
        FolderListComponent,
        SearchFolderListComponent,
        DisplayTimePipe,
        AdvancedContactSearchComponent,
        AdvancedSearchDialogComponent,
        AutoFocusDirective,
        AppointmentReminderDialogComponent,
        MoveCalendarDialogComponent,
        ModifyRecurAppointmentDialogComponent,
        ModifyAppointmentConfirmDialogComponent,
        DateRangeSelectDialogComponent,
        RecoverCalendarDeleteComponent,
        ConfirmSendInviteDialogComponent,
        RemoveAllExceptionDialogComponent,
        DeleteSeriesConfirmDialogComponent,
        CalendarPrintDialogComponent,
        CalendarEquipmentDialogComponent,
        CalendarEquipmentAutoCompleteComponent,
        ConflictEquipmentDialogComponent,
        SaveChangeAppointmentDialogComponent,
        CalendarFindLocationDialogComponent,
        CalendarMobileSelectAddressDialogComponent,
        CalendarSelectAddressesDialogComponent,
        CustomRepeatDialogComponent,
        DeleteAppointmentNotifyDialogComponent,
        AddExternalCalendarDialogComponent,
        MobileCalendarContextMenuDialogComponent,
        TFASettingsComponent,
        GeneralConfirmationDialogComponent,
        FindShareDialogComponent,
        SendLaterDialogComponent,
        MailDetailEmailOperationDialogComponent,
        AppearanceDialogComponent,
        ResizableModule,
        AttachContactDialogComponent,
        AddItemDialogComponent,
        CreateCalendarItemFolderComponent,
        AppointmentInviteReplyDialogComponent,
        EmailNotificationConfigureComponent,
        SealAndSendSnackbarComponent,
        VncLibraryModule,
        RightSidebarComponent
    ],
    declarations: [
        // Directives
        ...DIRECTIVES,
        // Dialogues
        ...DIALOGUES,
        // Pipes
        ...PIPES,
        VNCLongPressDirective,
        UserAvatarComponent,
        VNCStripProgress,
        FormatFileSizePipe,
        TagNameSearchPipe,
        FolderNameSearchPipe,
        MailOperationButtonComponent,
        SafeHtmlPipe,
        SortMessagesPipe,
        AppSwitcherComponent,
        VirtualScrollingComponent,
        CustomSpinnerComponent,
        AutocompleteComponent,
        TagsListComponent,
        FolderListComponent,
        SearchFolderListComponent,
        DisplayTimePipe,
        AdvancedContactSearchComponent,
        AdvancedSearchDialogComponent,
        FilePreviewOverlayComponent,
        FilePreviewOverlayToolbarComponent,
        AutoFocusDirective,
        AppointmentReminderDialogComponent,
        MoveCalendarDialogComponent,
        ModifyRecurAppointmentDialogComponent,
        ModifyAppointmentConfirmDialogComponent,
        DateRangeSelectDialogComponent,
        RecoverCalendarDeleteComponent,
        ConfirmSendInviteDialogComponent,
        RemoveAllExceptionDialogComponent,
        DeleteSeriesConfirmDialogComponent,
        CalendarPrintDialogComponent,
        CalendarEquipmentDialogComponent,
        CalendarEquipmentAutoCompleteComponent,
        ConflictEquipmentDialogComponent,
        SaveChangeAppointmentDialogComponent,
        CalendarFindLocationDialogComponent,
        CalendarMobileSelectAddressDialogComponent,
        CalendarSelectAddressesDialogComponent,
        CustomRepeatDialogComponent,
        DeleteAppointmentNotifyDialogComponent,
        AddExternalCalendarDialogComponent,
        MobileCalendarContextMenuDialogComponent,
        TFASettingsComponent,
        GeneralConfirmationDialogComponent,
        FindShareDialogComponent,
        SendLaterDialogComponent,
        MailDetailEmailOperationDialogComponent,
        AppearanceDialogComponent,
        AttachContactDialogComponent,
        ContactTooltipWindowComponent,
        AddItemDialogComponent,
        CreateCalendarItemFolderComponent,
        EmailNotificationConfigureComponent,
        SealAndSendSnackbarComponent,
        RightSidebarComponent
    ],
    providers: [
        VNCLongPressDirective,
        FormatFileSizePipe,
        TagNameSearchPipe,
        FolderNameSearchPipe,
        SidebarService,
        DisplayTimePipe,
        FilePreviewOverlayService,
        AutoFocusDirective,
        {
          provide: ContextMenuService,
          useExisting: ContextMenuFixService
      }
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CanDeactivateGuard
      ]
    };
  }
}
